import { Easing } from "react-native-reanimated";

export const AnimationEasings = {
  standard: Easing.bezier(0.4, 0.14, 0.3, 1),
  entrance: Easing.bezier(0, 0, 0.3, 1),
  exit: Easing.bezier(0.4, 0.14, 1, 1),
};

export const AnimationDurations = {
  fast01: 70,
  fast02: 110,
  moderate01: 150,
  moderate02: 240,
  slow01: 400,
  slow02: 700,
};

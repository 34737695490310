import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path, Circle } from "react-native-svg";

export const WaitlistIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" color="#001A19" {...props}>
    <Path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3 13H1M4 8H1M15 3H1"
      fill="none"
    />
    <Circle
      cx={10.5}
      cy={10.5}
      r={4.5}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      fill="none"
    />
    <Path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.5 9v2l1 1"
      fill="none"
    />
  </Icon>
);

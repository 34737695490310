import { useResponsiveProp } from "@shopify/restyle";
import { ReactElement } from "react";
import React from "react";
import { ScrollView, KeyboardAvoidingView, Platform } from "react-native";

import { Button } from "../../controls/Button/Button";
import { CloseIcon } from "../../icons_v2/general";
import { Box, Text, HStack } from "../../primitive";
import { Modal } from "../Modal";
import { getModalWidth } from "./utils";

type PopUpModalProps = {
  upperTitle?: string;
  content?: ReactElement;
  isOpen?: boolean;
  onClose?: (confirm: boolean) => void;
  footerExtraContent?: ReactElement;
  confirmText?: string;
  cancelText?: string;
  theme?: "neutral" | "danger";
  size?: "small" | "medium" | "large";
  isConfirmDisabled?: boolean;
  isConfirmVisible?: boolean;
};

export const PopUpModal = ({
  upperTitle,
  content,
  isOpen,
  onClose,
  footerExtraContent,
  confirmText = "Confirm",
  cancelText = "Cancel",
  theme = "neutral",
  size = "medium",
  isConfirmDisabled = false,
  isConfirmVisible = true,
}: PopUpModalProps) => {
  const modalTestID = `popup-modal-${(upperTitle || "")
    .toLowerCase()
    .replace(/[^a-z0-9]+/gi, "-")}`;

  const handleClose = () => {
    onClose?.(false);
  };

  const handleConfirm = () => {
    onClose?.(true);
  };

  const isMobileLayout = useResponsiveProp({
    base: 1,
    md: 0,
  });

  const isIOS = Platform.OS === "ios";

  const modalWidth = getModalWidth(size);

  return (
    <Modal isOpen={isOpen ?? false} onClose={handleClose}>
      {!isOpen ? null : (
        <Box
          overflow={"hidden"}
          borderRadius={{ base: 0, md: "md" }}
          height={{ base: "100%", md: "auto" }}
          width={modalWidth}
          testID={modalTestID}
          padding={6}
          paddingTop={isIOS ? "1000" : 6}
          paddingBottom={isIOS ? "600" : 6}
          backgroundColor="surface-primary"
        >
          <KeyboardAvoidingView
            style={{ width: "100%", height: "100%" }}
            behavior="padding"
          >
            {isMobileLayout ? (
              <Box marginBottom={6}>
                <Button
                  data-testid="modal-close-btn"
                  variant="alt"
                  onPress={() => {
                    handleClose();
                  }}
                  leftIcon={<CloseIcon />}
                />
              </Box>
            ) : null}
            {upperTitle ? (
              <Text variant={isMobileLayout ? "mainTitle2" : "mainHeading"}>
                {upperTitle}
              </Text>
            ) : null}
            <ScrollView
              style={{ flex: 1, paddingVertical: isMobileLayout ? 12 : 8 }}
            >
              {content}
              {footerExtraContent ? (
                <Box
                  marginBottom={2}
                  marginTop={4}
                  testID="footer-extra-content"
                >
                  {footerExtraContent}
                </Box>
              ) : null}
            </ScrollView>
            <Box marginTop={4}>
              <HStack justifyContent="flex-end" alignItems="flex-end">
                <HStack justifyContent="flex-end" gap={2}>
                  <Button onPress={handleClose} variant="alt" size="sm">
                    {cancelText}
                  </Button>
                  {isConfirmVisible ? (
                    <Button
                      onPress={handleConfirm}
                      variant={theme === "danger" ? "danger" : "main"}
                      size="sm"
                      isDisabled={isConfirmDisabled}
                    >
                      {confirmText}
                    </Button>
                  ) : null}
                </HStack>
              </HStack>
            </Box>
          </KeyboardAvoidingView>
        </Box>
      )}
    </Modal>
  );
};

import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const BellIconSlash = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" {...props}>
    <Path fill="currentColor" d="M6 14h4a2 2 0 1 1-4 0Z" />
    <Path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M3 13 14 2M12.929 7.182l-.5 2.818c0 .138.058.27.161.366l1.248 1.155a.501.501 0 0 1 .162.367c0 .28-.235.51-.524.51H6.714M3.5 8.5V7.182c0-2.103 1.129-3.872 3.132-4.49.233-.072.404-.274.404-.511v-.09C7.036 1.487 7.562 1 8.214 1c.652 0 1.179.487 1.179 1.09v.091c0 .237.17.439.403.51"
    />
  </Icon>
);

import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path, Rect } from "react-native-svg";

export const DuplicateIcon = (props: IIconProps) => (
  <Icon fill="currentColor" viewBox="0 0 16 16" {...props}>
    <Rect
      x="7"
      y="7"
      width="7"
      height="7"
      stroke="#001A19"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={"none"}
    />
    <Path
      d="M8 4V2H2V8H4"
      stroke="#001A19"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={"none"}
    />
  </Icon>
);

import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { G, Rect, Defs, ClipPath, Path } from "react-native-svg";

export const EmptyStateIcon = (props: IIconProps) => {
  return (
    <Icon
      fill="none"
      viewBox="0 0 80 80"
      color="currentColor"
      style={{ width: 80, height: 80 }}
      {...props}
    >
      <Rect width={80} height={80} fill="#F7F7F7" rx={40} />
      <G filter="url(#b)">
        <Rect width={60} height={56} x={10} y={24} fill="#fff" rx={4} />
      </G>
      <Path
        fill="#EDEDED"
        d="M18 33a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v1.5a1 1 0 0 1-1 1H19a1 1 0 0 1-1-1V33ZM18 40.5a1 1 0 0 1 1-1h42a1 1 0 0 1 1 1V52a1 1 0 0 1-1 1H19a1 1 0 0 1-1-1V40.5ZM19 57a1 1 0 0 0-1 1v11.5a1 1 0 0 0 1 1h42a1 1 0 0 0 1-1V58a1 1 0 0 0-1-1H19Z"
      />
      <Defs>
        <ClipPath id="a">
          <Rect width={80} height={80} fill="#fff" rx={40} />
        </ClipPath>
      </Defs>
    </Icon>
  );
};

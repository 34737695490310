import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const TeeSheetIcon = (props: IIconProps) => (
  <Icon width={24} height={24} fill="none" {...props}>
    <Path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M1 2h14M1 6h5M1 14h5M1 10h2M7 10h2M13 10h2M10 6h5M10 14h5"
    />
  </Icon>
);

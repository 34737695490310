import { whooshPrimitives } from "./primitives";

export type WhooshOutlineColorTokens = {
  "outline-default": string;
  "outline-secondary": string;
  "outline-inverse": string;
  "outline-primary": string;
  "outline-main-primary": string;
  "outline-main-secondary": string;
  "outline-accent-blue": string;
  "outline-accent-purple": string;
  "outline-accent-pink": string;
  "outline-status-success": string;
  "outline-status-warning": string;
  "outline-status-error": string;
};

export const whooshOutlineColors: WhooshOutlineColorTokens = {
  "outline-default": whooshPrimitives.grayscale[500],
  "outline-secondary": whooshPrimitives.grayscale[0],
  "outline-inverse": whooshPrimitives.grayscale[0],
  "outline-primary": whooshPrimitives.grayscale[1000],
  "outline-main-primary": whooshPrimitives.main.primary[500],
  "outline-main-secondary": whooshPrimitives.main.secondary[500],
  "outline-accent-blue": whooshPrimitives.accent.blue[500],
  "outline-accent-purple": whooshPrimitives.accent.purple[500],
  "outline-accent-pink": whooshPrimitives.accent.pink[500],
  "outline-status-success": whooshPrimitives.status.success[500],
  "outline-status-warning": whooshPrimitives.status.warning[500],
  "outline-status-error": whooshPrimitives.status.error[500],
};

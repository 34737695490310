import {
  Button as NativeBaseButton,
  IButtonProps,
  Box,
  Text,
} from "native-base";
import { ResponsiveValue } from "native-base/lib/typescript/components/types";
import React from "react";

import { LoadingSpinner } from "../../display/Loader/LoadingSpinner";

export type ButtonProps = Pick<
  IButtonProps,
  | "children"
  | "color"
  | "leftIcon"
  | "isDisabled"
  | "onPress"
  | "size"
  | "rightIcon"
  | "variant"
  | "w"
  | "aria-label"
  | "flex"
  | "flexGrow"
  | "flexShrink"
  | "justifyContent"
  | "p"
  | "h"
  | "bg"
> & {
  "data-testid"?: string;
  testID?: string;
  badgeNumber?: number;
  isLoading?: boolean;
  accessibilityLabel?: string;
  isPressed?: boolean;
};

export const Button = (props: ButtonProps) => {
  const { children, w, badgeNumber, variant, isLoading } = props;
  const width = children ? w || "auto" : undefined;

  return (
    <>
      <NativeBaseButton
        {...props}
        data-cy={props["testID"] ?? props["data-testid"] ?? undefined}
        testID={props["testID"] ?? props["data-testid"] ?? undefined}
        width={width}
        aria-label={props["aria-label"] || props.accessibilityLabel || "Button"}
        isPressed={props.isPressed}
      />
      {badgeNumber !== undefined ? <Badge number={badgeNumber} /> : null}
      {isLoading ? <ButtonSpinner variant={variant} /> : null}
    </>
  );
};

const Badge = ({ number }: { number: number }) => {
  return (
    <Box
      position="absolute"
      bottom="-4px"
      right="-4px"
      w="6"
      h="6"
      zIndex={2}
      bg="outline-inverse"
      borderRadius={6}
      p="2px"
    >
      <Box
        w="5"
        h="5"
        bg="main.primary.500"
        justifyContent="center"
        alignItems="center"
        borderRadius={4}
      >
        <Text variant="mainSmallSemiBold" color="text-inverse">
          {number}
        </Text>
      </Box>
    </Box>
  );
};

type LoadingSpinnerVariant = ResponsiveValue<
  | "main"
  | "alt"
  | "white"
  | "danger"
  | "ghost"
  | "outline"
  | "solid"
  | "subtle"
  | "link"
  | "unstyled"
  | (string & {})
>;

const ButtonSpinner = ({ variant }: { variant: LoadingSpinnerVariant }) => {
  const color = getLoadingSpinnerColor(variant);
  const bg = getLoadingSpinnerBG(variant);

  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bg={bg}
      alignItems="center"
      justifyContent="center"
      borderRadius="8px"
    >
      <LoadingSpinner size="md" color={color} />
    </Box>
  );
};

const getLoadingSpinnerBG = (variant: LoadingSpinnerVariant) => {
  switch (variant) {
    case "main":
      return "button-main-surface-neutral";
    case "white":
    case "ghost":
      return "button-white-surface-neutral";
    case "danger":
      return "button-danger-surface-neutral";
    case "alt":
    default:
      return "button-alt-surface-neutral";
  }
};

const getLoadingSpinnerColor = (variant: LoadingSpinnerVariant) => {
  switch (variant) {
    case "main":
      return "icon-inverse";
    case "white":
      return "text-secondary";
    case "danger":
      return "button-danger-icon";
    case "alt":
      return "icon-primary";
    default:
      return "button-alt-icon";
  }
};

import { Icon, IIconProps } from "native-base";
import * as React from "react";
import { G, Path, Defs, ClipPath, Circle } from "react-native-svg";

export const InfoIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        fill="none"
        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14ZM8 11V8"
      />
      <Circle cx={8} cy={5} r={1} fill="currentColor" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Icon>
);

export default InfoIcon;

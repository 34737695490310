import { extendTheme } from "native-base";

import { WhooshTheme } from "@whoosh/design-system/src";
import {
  whooshPrimitives,
  WhooshThemePrimitives,
} from "@whoosh/design-system/src/theme/colors/primitives";

export type WhooshThemeBase = {
  colors: WhooshThemePrimitives;
  typography: {
    mainFont: string;
    secondaryFont: string;
  };
};

export const whooshThemeBase: WhooshThemeBase = {
  colors: {
    ...whooshPrimitives,
  },
  typography: {
    mainFont: "'Inter', sans-serif",
    //secondaryFont: "'Calluna', serif",
    secondaryFont: "'Tinos', serif",
  },
};

export enum DesignSize {
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl",
}

export enum WhooshVariants {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  DANGER = "danger",
  AFFIRM = "affirm",
  MEMBER = "member",
  GUEST = "guest",
  DIM = "dim",
  FLAT = "flat",
  BLACK = "black",
  TERTIARY = "tertiary",
}

export interface VariantStyles {
  backgroundColor: string;
  borderColor: string;
  color: string;
}

/**
 * Broke out the ole variants so we can share styles more easily for them, as well as re-theme them more easily
 */
type AllWhooshVariantStyles = { [key in WhooshVariants]: VariantStyles };
type WhooshVariantStyles = {
  variantStyles: AllWhooshVariantStyles;
};

const whooshThemeVariants: WhooshVariantStyles = {
  variantStyles: {
    [WhooshVariants.PRIMARY]: {
      backgroundColor: whooshThemeBase.colors.outdated.primary[500],
      color: whooshThemeBase.colors.grayscale[0],
      borderColor: whooshThemeBase.colors.outdated.neutrals._70,
    },
    [WhooshVariants.SECONDARY]: {
      backgroundColor: whooshThemeBase.colors.grayscale[0],
      color: whooshThemeBase.colors.main.primary[1000],
      borderColor: whooshThemeBase.colors.outdated.neutrals[600],
    },
    [WhooshVariants.MEMBER]: {
      backgroundColor: whooshThemeBase.colors.main.primary[300],
      color: whooshThemeBase.colors.main.primary[1000],
      borderColor: whooshThemeBase.colors.outdated.neutrals._70,
    },
    [WhooshVariants.DANGER]: {
      backgroundColor: whooshThemeBase.colors.outdated.guest._70,
      color: whooshThemeBase.colors.outdated.semantic.error.base,
      borderColor: whooshThemeBase.colors.outdated.neutrals._70,
    },
    [WhooshVariants.GUEST]: {
      backgroundColor: whooshThemeBase.colors.outdated.guest._70,
      color: whooshThemeBase.colors.main.secondary[1000],
      borderColor: whooshThemeBase.colors.outdated.neutrals._70,
    },
    [WhooshVariants.DIM]: {
      backgroundColor: whooshThemeBase.colors.grayscale[100],
      color: whooshThemeBase.colors.main.primary[1000],
      borderColor: whooshThemeBase.colors.main.primary[1000],
    },
    [WhooshVariants.FLAT]: {
      backgroundColor: whooshThemeBase.colors.outdated.neutrals.transparent,
      color: whooshThemeBase.colors.main.primary[1000],
      borderColor: whooshThemeBase.colors.outdated.neutrals.transparent,
    },
    [WhooshVariants.BLACK]: {
      backgroundColor: whooshThemeBase.colors.outdated.neutrals.black,
      color: whooshThemeBase.colors.grayscale[0],
      borderColor: whooshThemeBase.colors.outdated.neutrals.black,
    },
    [WhooshVariants.AFFIRM]: {
      backgroundColor: whooshThemeBase.colors.main.primary[100],
      color: whooshThemeBase.colors.main.primary[500],
      borderColor: whooshThemeBase.colors.outdated.neutrals._70,
    },
    [WhooshVariants.TERTIARY]: {
      backgroundColor: whooshThemeBase.colors.grayscale[0],
      color: whooshThemeBase.colors.main.primary[1000],
      borderColor: whooshThemeBase.colors.main.primary[1000],
    },
  },
};
export type WhooshThemeStyles = WhooshThemeBase & WhooshVariantStyles;
export const whooshTheme: WhooshThemeStyles = {
  ...whooshThemeBase,
  ...whooshThemeVariants,
};

export const WhooshNBStaffTheme = extendTheme({
  ...WhooshTheme,
  fontConfig: {
    Calluna: {
      100: {
        normal: "'Tinos'",
        bold: "'Tinos'",
      },
      200: {
        normal: "'Tinos'",
        bold: "'Tinos'",
      },
      300: {
        normal: "'Tinos'",
        bold: "'Tinos'",
      },
      400: {
        normal: "'Tinos'",
        bold: "'Tinos'",
      },
      500: {
        normal: "'Tinos'",
        bold: "'Tinos'",
      },
      600: {
        normal: "'Tinos'",
        bold: "'Tinos'",
      },
      700: {
        normal: "'Tinos'",
        bold: "'Tinos'",
      },
      800: {
        normal: "'Tinos'",
        bold: "'Tinos'",
      },
      900: {
        normal: "'Tinos'",
        bold: "'Tinos'",
      },
    },
    Inter: {
      100: {
        normal: "Inter",
        bold: "Inter",
      },
      200: {
        normal: "Inter",
        bold: "Inter",
      },
      300: {
        normal: "Inter",
        bold: "Inter",
      },
      400: {
        normal: "Inter",
        bold: "Inter",
      },
      500: {
        normal: "Inter",
        bold: "Inter",
      },
      600: {
        normal: "Inter",
        bold: "Inter",
      },
      700: {
        normal: "Inter",
        bold: "Inter",
      },
      800: {
        normal: "Inter",
        bold: "Inter",
      },
    },
  },
  fonts: {
    heading: "Tinos",
    body: "Inter",
    mono: "Inter",
  },
  components: {
    ...WhooshTheme.components,
    Heading: {
      defaultProps: {
        letterSpacing: -0.2,
        lineHeight: "28px",
        fontWeight: "bold",
        fontVariant: ["lining-nums", "tabular-nums"],
        fontFamily: "heading",
      },
      baseStyle: {
        color: "grayscale.900",
      },
    },
  },
});

import { t } from "@lingui/macro";
import React from "react";

import { Button } from "@/design-system/controls/Button";

import { UI } from "./ui";

export interface iErrorScreenTemplateProps {
  title?: string;
  message?: string;
  buttonText?: string;
  onTryAgainClick?: () => void;
  logoutButton?: JSX.Element;
  subMessage?: string;
}
export const ErrorScreenTemplate = (props: iErrorScreenTemplateProps) => {
  return (
    <UI.Wrapper>
      <UI.Content>
        <UI.Title>{props.title}</UI.Title>
        <UI.Paragraph>{props.message}</UI.Paragraph>
        <UI.Paragraph>{props.subMessage}</UI.Paragraph>
        <UI.ButtonWrapper>
          {!!props.onTryAgainClick && (
            <Button
              onClick={props.onTryAgainClick}
            >{t`Let's Try Again`}</Button>
          )}
          {props.logoutButton}
        </UI.ButtonWrapper>
      </UI.Content>
    </UI.Wrapper>
  );
};

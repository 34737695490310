import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { G, Path } from "react-native-svg";

export const MembersIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props} fillOpacity="0">
    <G clipPath="url(#clip0_2075_39066)">
      <Path
        d="M11 14.5V13.1667C11 12.4594 10.7366 11.7811 10.2678 11.281C9.79893 10.781 9.16304 10.5 8.5 10.5H3.5C2.83696 10.5 2.20107 10.781 1.73223 11.281C1.26339 11.7811 1 12.4594 1 13.1667V14.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <Path
        d="M15 12.5V11.1667C15 10.4594 14.7366 9.78115 14.2678 9.28105C13.7989 8.78095 13.163 8.5 12.5 8.5H10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <Path
        d="M6 7.5C7.10457 7.5 8 6.60457 8 5.5C8 4.39543 7.10457 3.5 6 3.5C4.89543 3.5 4 4.39543 4 5.5C4 6.60457 4.89543 7.5 6 7.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <Path
        d="M11 5.5C12.1046 5.5 13 4.60457 13 3.5C13 2.39543 12.1046 1.5 11 1.5C10.4027 1.5 9.86647 1.76188 9.5 2.17709"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </G>
  </Icon>
);

import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path, G, Defs, ClipPath } from "react-native-svg";

export const AddNoteIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" {...props}>
    <G
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      clipPath="url(#a)"
      fill="none"
    >
      <Path d="M14 6V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h3M1 4.5h2M6 4.5h5M1 8h2M6 8h2M1 11.5h2M15 12H9M12 9v6" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="none" d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Icon>
);

import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const TrophyIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" color="currentColor" {...props}>
    <Path
      fill="currentColor"
      d="M11.111 14v.75a.75.75 0 0 0 .75-.75h-.75ZM4.89 14h-.75c0 .414.336.75.75.75V14Zm3.917-2.25a.75.75 0 0 0 1.5 0h-1.5Zm.75-2.25-.326-.676a.75.75 0 0 0-.424.676h.75Zm3.11-7.5h.75a.75.75 0 0 0-.75-.75V2ZM3.334 2v-.75a.75.75 0 0 0-.75.75h.75Zm2.361 9.75a.75.75 0 0 0 1.5 0h-1.5Zm.75-2.25h.75a.75.75 0 0 0-.424-.676l-.326.676ZM15 4.25l.709.245a.75.75 0 0 0-.48-.959L15 4.25Zm-.778 2.25.23.714a.75.75 0 0 0 .48-.469l-.71-.245ZM1 4.25l-.23-.714a.75.75 0 0 0-.479.959L1 4.25Zm.778 2.25-.71.245a.75.75 0 0 0 .48.469l.23-.714Zm3.889 6h4.666V11H5.667v1.5Zm4.694 0V14h1.5v-1.5h-1.5Zm.75.75H4.89v1.5h6.222v-1.5ZM5.64 14v-1.5h-1.5V14h1.5Zm4.694-1.5a.05.05 0 0 1 .02.003.03.03 0 0 1 .01.007c.002.001.001.002 0 0a.025.025 0 0 1-.002-.01h1.5c0-.854-.71-1.5-1.528-1.5v1.5ZM5.667 11c-.818 0-1.528.646-1.528 1.5h1.5c0 .003 0 .006-.002.01-.001.002-.002.001 0 0a.03.03 0 0 1 .01-.007.051.051 0 0 1 .02-.003V11Zm4.639.75V9.5h-1.5v2.25h1.5Zm-.425-1.574c1.215-.586 2.19-1.209 2.787-2.51.561-1.224.749-2.969.749-5.666h-1.5c0 2.703-.202 4.145-.612 5.04-.376.818-.956 1.245-2.075 1.784l.651 1.352Zm2.786-8.926H3.333v1.5h9.334v-1.5Zm-5.473 10.5V9.5h-1.5v2.25h1.5ZM6.77 8.824c-1.119-.539-1.7-.966-2.074-1.784-.411-.895-.613-2.337-.613-5.04h-1.5c0 2.697.188 4.442.75 5.666.596 1.301 1.571 1.924 2.786 2.51l.651-1.352Zm5.667-4.61 2.334.75.458-1.428-2.333-.75-.459 1.428Zm1.854-.209-.778 2.25 1.418.49.778-2.25-1.418-.49Zm-.298 1.781-2.334.75.46 1.428 2.333-.75-.46-1.428Zm-10.89-3-2.333.75.46 1.428 2.333-.75-.46-1.428ZM.292 4.495l.778 2.25 1.418-.49-.778-2.25-1.418.49Zm1.257 2.719 2.334.75.459-1.428-2.334-.75-.459 1.428Z"
    />
  </Icon>
);

import { t } from "@lingui/macro";
import React, { useCallback, useEffect } from "react";

import { useIsOnline } from "@/utilities/NetworkStatus";
import { ShowToastProps } from "@whoosh/design-system/src/components/overlay/Toast/types";
import { useToast } from "@whoosh/design-system/src/components/overlay/Toast/useToast";

export const OfflineToast = () => {
  const { showToast, closeToast } = useToast();

  const addOfflineToast = useCallback(() => {
    const offlineToast: ShowToastProps = {
      toastId: "offline-message",
      title: t`You are Currently Offline. Some features will be unavailable.`,
      semanticStyle: "error",
    };

    showToast(offlineToast);
  }, [showToast]);

  const removeOfflineToast = useCallback(() => {
    closeToast("offline-message");
  }, [closeToast]);

  const isOnline = useIsOnline();
  useEffect(() => {
    if (isOnline) {
      removeOfflineToast();
    }
    // If we remain offline for 2 seconds, show the message
    const interval = setTimeout(() => {
      if (navigator && !navigator.onLine && !isOnline) {
        addOfflineToast();
      }
    }, 2000);
    return () => {
      clearTimeout(interval);
    };
  }, [addOfflineToast, isOnline, removeOfflineToast]);
  return <></>;
};

import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path, G, Svg } from "react-native-svg";

export const BadgePercentIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 20 20" {...props}>
    <Svg fill="none">
      <G>
        <Path
          d="M3.20813 7.18358C3.0865 6.63568 3.10517 6.06595 3.26243 5.52719C3.41968 4.98844 3.71042 4.49811 4.10768 4.10168C4.50495 3.70524 4.99589 3.41553 5.53497 3.25941C6.07405 3.10328 6.64382 3.0858 7.19146 3.20858C7.49289 2.73716 7.90813 2.34921 8.39892 2.08048C8.88971 1.81175 9.44025 1.6709 9.99979 1.6709C10.5593 1.6709 11.1099 1.81175 11.6007 2.08048C12.0915 2.34921 12.5067 2.73716 12.8081 3.20858C13.3566 3.08526 13.9274 3.10267 14.4673 3.25917C15.0072 3.41568 15.4988 3.70619 15.8963 4.10371C16.2938 4.50122 16.5844 4.9928 16.7409 5.53275C16.8974 6.07269 16.9148 6.64344 16.7915 7.19191C17.2629 7.49334 17.6508 7.90858 17.9196 8.39937C18.1883 8.89016 18.3291 9.4407 18.3291 10.0002C18.3291 10.5598 18.1883 11.1103 17.9196 11.6011C17.6508 12.0919 17.2629 12.5072 16.7915 12.8086C16.9142 13.3562 16.8968 13.926 16.7406 14.4651C16.5845 15.0042 16.2948 15.4951 15.8984 15.8924C15.5019 16.2896 15.0116 16.5804 14.4728 16.7376C13.9341 16.8949 13.3644 16.9135 12.8165 16.7919C12.5154 17.2651 12.0999 17.6547 11.6082 17.9247C11.1166 18.1946 10.5648 18.3361 10.004 18.3361C9.44311 18.3361 8.89132 18.1946 8.39969 17.9247C7.90806 17.6547 7.49249 17.2651 7.19146 16.7919C6.64382 16.9147 6.07405 16.8972 5.53497 16.7411C4.99589 16.585 4.50495 16.2952 4.10768 15.8988C3.71042 15.5024 3.41968 15.012 3.26243 14.4733C3.10517 13.9345 3.0865 13.3648 3.20813 12.8169C2.73309 12.5163 2.34181 12.1004 2.07067 11.6079C1.79953 11.1155 1.65735 10.5624 1.65735 10.0002C1.65735 9.43807 1.79953 8.88503 2.07067 8.39257C2.34181 7.9001 2.73309 7.48421 3.20813 7.18358Z"
          stroke="currentColor"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12.5 7.5L7.5 12.5"
          stroke="currentColor"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M7.5 7.5H7.50583"
          stroke="currentColor"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12.5 12.5H12.5058"
          stroke="currentColor"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
    </Svg>
  </Icon>
);

import * as FullStory from "@fullstory/browser";

import { getEnvironment } from "../Environment";

let fullStoryEnabled = false;

export const initFullStory = () => {
  const orgId = getEnvironment().FULLSTORY_TOKEN;
  if (orgId) {
    FullStory.init({ orgId });

    fullStoryEnabled = true;
  }
};

export interface iIdentityProps {
  email: string | undefined;
  username: string;
  facility: string;
  masqueraderEmail: string;
}

export const identify = (id: string, props: iIdentityProps) => {
  if (fullStoryEnabled) {
    FullStory.identify(id, props);
  }
};

import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const CopyIcon = (props: IIconProps) => (
  <Icon fill="currentColor" viewBox="0 0 16 16" {...props}>
    <Path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.25 4H4.5v0A3.5 3.5 0 0 0 1 7.5v1A3.5 3.5 0 0 0 4.5 12v0h1.75m3.5-8h1.75v0A3.5 3.5 0 0 1 15 7.5v1a3.5 3.5 0 0 1-3.5 3.5v0H9.75M5.5 8h5"
      stroke="#001A19"
      fill="none"
    />
  </Icon>
);

import { Icon, IIconProps } from "native-base";
import * as React from "react";
import { G, Path, Defs, ClipPath } from "react-native-svg";

export const StatusInformationOutlineIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <G clipPath="url(#clip0_6795_15828)" fill="#001A19">
      <Path d="M6.25 6.5A.75.75 0 017 5.75h1a.75.75 0 01.75.75v4.25H10a.75.75 0 010 1.5H6a.75.75 0 010-1.5h1.25v-3.5H7a.75.75 0 01-.75-.75zM8 5a1 1 0 100-2 1 1 0 000 2z" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16A8 8 0 108 0a8 8 0 000 16zm0-1.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13z"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_6795_15828">
        <Path fill="#fff" d="M0 0H16V16H0z" />
      </ClipPath>
    </Defs>
  </Icon>
);

export default StatusInformationOutlineIcon;

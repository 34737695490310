import { AnimatePresence, MotiView } from "moti";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Platform, Modal as RNModal } from "react-native";

import { useKeyboardBottomInset } from "../../../hooks/useKeyboardBottomInset";
import { usePreventScroll } from "../../../hooks/usePreventScroll";
import {
  AnimationDurations,
  AnimationEasings,
} from "../../../theme/animations";
import { Overlay } from "../Overlay/Overlay";
import { WhooshToastProvider } from "../Toast/WhooshToastProvider";
import { ModalProps } from "./types";
import { useIsModalFocused } from "./useIsModalFocused";

export const Modal = ({ isOpen, onClose, children }: ModalProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const [internalIsOpen, setInternalIsOpen] = useState(isOpen);
  const isFocused = useIsModalFocused();

  const handleInternalClose = useCallback(() => {
    setInternalIsOpen(false);
    setIsClosing(false);
    onClose?.();
  }, [onClose]);

  const handleClose = useCallback(() => {
    if (onClose) {
      setIsClosing(true);
      handleInternalClose();
    }
  }, [handleInternalClose, onClose]);

  useEffect(() => {
    if (isOpen) {
      /**
       * When we open -- we need to slightly delay the open
       * by the same duration as our debounced close + a small buffer.
       *
       * This is because otherwise opening one modal while closing another can cause a clash
       * resulting in the 2nd modal getting stuck. This is related to using 'transparent=true'
       * I believe.
       *
       * GH: https://github.com/facebook/react-native/issues/29929
       */

      setIsClosing(false);
      setInternalIsOpen(true);
    } else if (!isOpen && internalIsOpen) {
      setIsClosing(true);
      handleInternalClose?.();
    }
  }, [handleInternalClose, internalIsOpen, isOpen]);

  usePreventScroll({ isOpen });

  const isModalOpen = isOpen || internalIsOpen;
  return isFocused ? (
    <RNModal
      visible={isModalOpen}
      transparent={true}
      onRequestClose={handleClose}
    >
      <WhooshToastProvider>
        <Content isClosing={isClosing}>
          <Overlay onPress={handleClose} isClosing={isClosing} />
          {children}
        </Content>
      </WhooshToastProvider>
    </RNModal>
  ) : null;
};

const fadeUpAnimation = {
  from: { opacity: 0, top: -30 },
  animate: { opacity: 1, top: 0 },
  exit: { opacity: 0, top: -30 },
};

const Content = ({
  isClosing,
  children,
}: {
  isClosing: boolean;
  children: ReactNode;
}) => {
  const bottomInset = useKeyboardBottomInset();
  return (
    <AnimatePresence>
      {isClosing ? null : (
        <MotiView
          key="modalcontent"
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: -30,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            paddingBottom: Platform.OS === "android" ? bottomInset : 0,
          }}
          {...fadeUpAnimation}
          transition={{
            opacity: {
              type: "timing",
              duration: AnimationDurations.moderate02,
              easing: isClosing
                ? AnimationEasings.exit
                : AnimationEasings.entrance,
            },
            top: {
              type: "timing",
              duration: AnimationDurations.moderate02,
              easing: isClosing
                ? AnimationEasings.exit
                : AnimationEasings.entrance,
            },
          }}
        >
          {children}
        </MotiView>
      )}
    </AnimatePresence>
  );
};

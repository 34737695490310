import { IIconProps, Icon } from "native-base";
import React from "react";
import { Path } from "react-native-svg";

export const FlagWithXIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      stroke={"currentColor"}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      strokeWidth={1.333}
      d="M13.333 4.5V2s-.666.667-2.666.667-3.334-1.334-5.334-1.334c-2 0-2.666.667-2.666.667v9s.666-.667 2.666-.667c.318 0 .62.034.909.09"
    />
    <Path
      stroke={"currentColor"}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      strokeWidth={1.5}
      d="m10 11.5 4-4M14 11.5l-4-4"
    />
    <Path
      stroke={"currentColor"}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      strokeWidth={1.333}
      d="M2.667 14.667V10"
    />
  </Icon>
);

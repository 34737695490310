/**
 * These types exact match our figma color keys. They don't all define the same keys.
 * This is done so that we DO NOT define keys that aren't actually defined, and cause engineers
 * to accidentally use the wrong key. It also ensures intellisense only hints actually defined colors
 */

export type TenShadeColor = {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
  1000: string;
};

export type WhooshThemePrimitives = {
  grayscale: {
    0: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
    1000: string;
  };
  main: {
    primary: TenShadeColor;
    secondary: TenShadeColor;
  };
  accent: {
    blue: TenShadeColor;
    purple: TenShadeColor;
    pink: TenShadeColor;
  };
  //TODO: Deprecated. Remove these 'sub' colors.
  sub: {
    alpha: {
      100: string;
      200: string;
      300: string;
      500: string;
      1000: string;
    };
    beta: {
      100: string;
      300: string;
      500: string;
      1000: string;
    };
    gamma: {
      100: string;
      300: string;
      500: string;
      1000: string;
    };
    delta: {
      100: string;
      300: string;
      500: string;
      1000: string;
    };
  };
  status: {
    error: TenShadeColor;
    warning: TenShadeColor;
    success: TenShadeColor;
  };
  userSelectable: {
    A: TenShadeColor;
    B: TenShadeColor;
    C: TenShadeColor;
    D: TenShadeColor;
    E: TenShadeColor;
    F: TenShadeColor;
  };
  outdated: {
    neutrals: {
      white: string;
      black: string;
      _55: string;
      _65: string;
      _70: string;
      _75: string;
      transparent: string;
      100: string;
      300: string;
      600: string;
      700: string;
      750: string;
      800: string;
      900: string;
      950: string;
    };
    primary: {
      _40: string;
      _50: string;
      _60: string;
      _85: string;
      300: string;
      400: string;
      500: string;
      pressed: string;
    };
    secondary: {
      base: string;
      _20: string;
      _40: string;
      _50: string;
      _55: string;
      _60: string;
    };
    member: {
      _40: string;
      _50: string;
      _60: string;
      _85: string;
    };
    guest: {
      _70: string;
      _85: string;
    };
    semantic: {
      success: {
        base: string;
        _20: string;
      };
      info: {
        base: string;
        _20: string;
      };
      warning: {
        base: string;
        _20: string;
        _40: string;
        _50: string;
        _55: string;
        _60: string;
        _70: string;
        _80: string;
        _85: string;
      };
      error: {
        base: string;
        _20: string;
        _40: string;
        _50: string;
        _55: string;
        _60: string;
        _70: string;
        _80: string;
        _85: string;
      };
    };
    events: {
      blue: string;
      blueLight: string;
      purple: string;
      purpleLight: string;
      red: string;
      redLight: string;
      green: string;
      greenLight: string;
      orange: string;
      orangeLight: string;
    };
    yellow: {
      _60: string;
      400: string;
    };
    danger: {
      300: string;
      400: string;
      600: string;
    };
    green: {
      400: string;
      600: string;
    };
    coral: {
      main: string;
    };
    caddie: {
      unconfirmed: string;
      unassigned: string;
    };
    weather: {
      lightBlue: string;
      blue: string;
    };
  };
};

export const whooshPrimitives: WhooshThemePrimitives = {
  main: {
    primary: {
      100: "#E1F5ED",
      200: "#D3F0E5",
      300: "#ACE5CF",
      400: "#5AC79E",
      500: "#1DAA75",
      600: "#1BA16F",
      700: "#199264",
      800: "#0C3B39",
      900: "#043331",
      1000: "#002726",
    },
    secondary: {
      100: "#FFECE5",
      200: "#FFE1D6",
      300: "#FFD9CC",
      400: "#FF9673",
      500: "#FF784B",
      600: "#F27247",
      700: "#E56C43",
      800: "#D9572B",
      900: "#4B1E09",
      1000: "#271000",
    },
  },
  accent: {
    blue: {
      100: "#E5F0FF",
      200: "#D3E5FF",
      300: "#BDD8FF",
      400: "#93BCF6",
      500: "#317AE0",
      600: "#2A70D1",
      700: "#2562B8",
      800: "#2A3950",
      900: "#15253D",
      1000: "#001027",
    },
    purple: {
      100: "#EDEBFF",
      200: "#D8D6FF",
      300: "#C6BDFF",
      400: "#9C90EF",
      500: "#7363E0",
      600: "#6858D1",
      700: "#5849B8",
      800: "#352F52",
      900: "#1E183D",
      1000: "#070027",
    },
    pink: {
      100: "#F1E5FF",
      200: "#E7D1FF",
      300: "#DCBDFF",
      400: "#D2ABFD",
      500: "#BA82FA",
      600: "#B078F0",
      700: "#9E69DB",
      800: "#402E52",
      900: "#2A173D",
      1000: "#140027",
    },
  },
  sub: {
    alpha: {
      100: "#FFFCEB",
      200: "#FFF8D6",
      300: "#FFF5BF",
      500: "#FFD600",
      1000: "#002726",
    },
    beta: {
      100: "#E5F0FF",
      300: "#BDD8FF",
      500: "#317AE0",
      1000: "#001027",
    },
    gamma: {
      100: "#EDEBFF",
      300: "#C6BDFF",
      500: "#7363E0",
      1000: "#070027",
    },
    delta: {
      100: "#F1E5FF",
      300: "#DCBDFF",
      500: "#BA82FA",
      1000: "#140027",
    },
  },
  status: {
    error: {
      100: "#FFEBEB",
      200: "#FFE0E0",
      300: "#FFCCCC",
      400: "#F6A7A7",
      500: "#E05151",
      600: "#CC4949",
      700: "#B84242",
      800: "#661A1A",
      900: "#440D0D",
      1000: "#270000",
    },
    warning: {
      100: "#FFFCEB",
      200: "#FFF8D6",
      300: "#FFF5BF",
      400: "#FFEC86",
      500: "#FFD600",
      600: "#F5CD00",
      700: "#EBC500",
      800: "#736000",
      900: "#4D4000",
      1000: "#272100",
    },
    success: {
      100: "#E1F5ED",
      200: "#CAF4E4",
      300: "#ACE5CF",
      400: "#5AC79E",
      500: "#1DAA75",
      600: "#1BA16F",
      700: "#199264",
      800: "#127A54",
      900: "#134D37",
      1000: "#002726",
    },
  },
  userSelectable: {
    A: {
      100: "#FFECE5",
      200: "#FFE1D6",
      300: "#FFD9CC",
      400: "#FF9673",
      500: "#FF784B",
      600: "#F27247",
      700: "#E56C43",
      800: "#D9572B",
      900: "#4B1E09",
      1000: "#271000",
    },
    B: {
      100: "#FFFCEB",
      200: "#FFF8D6",
      300: "#FFF5BF",
      400: "#FFEC86",
      500: "#FFD600",
      600: "#F5CD00",
      700: "#EBC500",
      800: "#736000",
      900: "#4D4000",
      1000: "#272100",
    },
    C: {
      100: "#E5F0FF",
      200: "#D3E5FF",
      300: "#BDD8FF",
      400: "#93BCF6",
      500: "#317AE0",
      600: "#2A70D1",
      700: "#2562B8",
      800: "#2A3950",
      900: "#15253D",
      1000: "#001027",
    },
    D: {
      100: "#EDEBFF",
      200: "#DBD6FF",
      300: "#C6BDFF",
      400: "#9C90EF",
      500: "#7363E0",
      600: "#6858D1",
      700: "#5849B8",
      800: "#352F52",
      900: "#1E183D",
      1000: "#070027",
    },
    E: {
      100: "#F1E5FF",
      200: "#E7D1FF",
      300: "#DCBDFF",
      400: "#D2ABFD",
      500: "#BA82FA",
      600: "#B078F0",
      700: "#9E69DB",
      800: "#402E52",
      900: "#2A173D",
      1000: "#140027",
    },
    F: {
      100: "#FFEBEB",
      200: "#FFE0E0",
      300: "#FFCCCC",
      400: "#F6A7A7",
      500: "#E05151",
      600: "#CC4949",
      700: "#B84242",
      800: "#661A1A",
      900: "#440D0D",
      1000: "#270000",
    },
  },
  grayscale: {
    0: "#FFFFFF",
    100: "#F7F7F7",
    200: "#F0F0F0",
    300: "#EDEDED",
    400: "#E9EBEB",
    500: "#E1E5E5",
    600: "#D2D9D9",
    700: "#BDC7C7",
    800: "#809392",
    900: "#405D5C",
    1000: "#001A19",
  },
  outdated: {
    neutrals: {
      white: "white",
      black: "#000000",
      _55: "#B8BFBF",
      _65: "#E5E5E5",
      _70: "#EBEBEB",
      _75: "#F2F2F2",
      transparent: "transparent",
      100: "#f0f0f0",
      300: "#809392",
      600: "#DBDBDB",
      700: "#F0F0F0",
      750: "#F5F5EB",
      800: "#F9F9F9",
      900: "#E5E5E5",
      950: "#002726",
    },
    primary: {
      _40: "#295F3E",
      _50: "#67877B",
      _60: "#41775B",
      _85: "#124734",
      300: "#A1D3C9",
      400: "#5CC1AD",
      500: "#00A887",
      pressed: "#00755e",
    },
    secondary: {
      base: "#A8A087",
      _20: "#3B372B",
      _40: "#3B372B",
      _50: "#3B372B",
      _55: "#3B372B",
      _60: "#3B372B",
    },
    member: {
      _40: "#295F3E",
      _50: "#67877B",
      _60: "#41775B",
      _85: "#124734",
    },
    guest: {
      _70: "#FFDDD1",
      _85: "#E5E3DC",
    },
    semantic: {
      success: {
        base: "#1DAA75",
        _20: "#188F62",
      },
      info: {
        base: "#809392",
        _20: "#405D5C",
      },
      warning: {
        base: "#E58B04",
        _20: "#E58B04",
        _40: "#E58B04",
        _50: "#E58B04",
        _55: "#E58B04",
        _60: "#E58B04",
        _70: "#FFE5B2",
        _80: "#FFE5B2",
        _85: "#FFE5B2",
      },
      error: {
        base: "#FF5959",
        _20: "#D64343",
        _40: "#D64343",
        _50: "#D64343",
        _55: "#D64343",
        _60: "#D64343",
        _70: "#FF5959",
        _80: "#FFE6E6",
        _85: "#FFE6E6",
      },
    },
    events: {
      blue: "#5C8BCC",
      blueLight: "#DFE8F2",
      purple: "#8C81D6",
      purpleLight: "#EDEBFA",
      red: "#CC7A8F",
      redLight: "#FAE6EB",
      green: "#70CCA6",
      greenLight: "#E1F5ED",
      orange: "#E5A88A",
      orangeLight: "#FFECE5",
    },
    yellow: {
      _60: "#EDE1A1",
      400: "#F5DC5C",
    },
    danger: {
      300: "#f9ebf0",
      400: "#F5857E",
      600: "#EDB5B2",
    },
    green: {
      400: "#ECFAEB",
      600: "#91C890",
    },
    coral: {
      main: "#ff4438",
    },
    caddie: {
      unconfirmed: "#FAF6CE",
      unassigned: "#FBE7E4",
    },
    weather: {
      lightBlue: "#E5F4FF",
      blue: "#2196F3",
    },
  },
};

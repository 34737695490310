import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const UploadIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 20 20" color="currentColor" {...props}>
    <Path
      id="basket"
      fill="none"
      d="M17 12L17 15.3333C17 15.7754 16.8361 16.1993 16.5444 16.5118C16.2527 16.8244 15.857 17 15.4444 17L4.55556 17C4.143 17 3.74733 16.8244 3.45561 16.5118C3.16389 16.1993 3 15.7754 3 15.3333L3 12"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      id="arrow-head"
      fill="none"
      d="M14 7L10 3L6 7"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      id="arrow-shaft"
      fill="none"
      d="M10 3L10 12"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

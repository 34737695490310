import styled from "styled-components/macro";

import Image from "./../Sand-Trap.jpg";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(${Image});
  background-size: cover;
  padding-top: 80px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 24px;
  border-radius: 8px;
  width: auto;
  position: fixed;
  width: 600px;
  left: 50%;
  margin-left: -300px;
  border: 1px solid black;

  @media screen and (max-width: 650px) {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    margin-left: 0;
    display: flex;
    justify-content: center;
    border-radius: 0;
    border: none;
  }
`;

const Title = styled.h1``;
const Paragraph = styled.p`
  font-size: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 40px;
`;

export const UI = { Wrapper, Content, Paragraph, Title, ButtonWrapper };

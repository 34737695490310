import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const KebabVerticalIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.5 3a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM8 14.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
      clipRule="evenodd"
    />
  </Icon>
);

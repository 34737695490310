import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { G, Circle, Path, Defs, ClipPath } from "react-native-svg";

export const SearchIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" {...props}>
    <G
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      clipPath="url(#a)"
      fill="none"
    >
      <Circle cx={7} cy={7} r={5.75} />
      <Path d="m14.5 14.5-3-3" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Icon>
);

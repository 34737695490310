import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";

import Loading from "@/design-system/overlay/Loading";

import { OfflineToast } from "../OfflineToast";
import { ServiceWorkerToast } from "../ServiceWorkerToast";

const StaffApp = lazy(() => import("@/staffApp/index"));
const GuestForms = lazy(() => import("@/guestForms/index"));
/**
 * It routes you between apps.
 *
 */
export const AppRouter = () => {
  return (
    <>
      <OfflineToast />
      <ServiceWorkerToast />
      <Switch>
        <Route path="/guest-info">
          <Suspense fallback={<Loading />}>
            <GuestForms />
          </Suspense>
        </Route>
        <Route path="/">
          <Suspense fallback={<Loading />}>
            <StaffApp />
          </Suspense>
        </Route>
      </Switch>
    </>
  );
};

import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

import { initFullStory } from "@/utilities/FullStory";
import { init as initSentry } from "@/utilities/Sentry";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import {
  PubSubGlobalSubjects,
  PubSubTopics,
  pubSubService,
} from "./utilities/PubSubService";

initFullStory();
initSentry();
/**
 *
 * When we detect an app update, we will begin spamming the user
 * with update requests every 5 minutes ( and also send one immediately)
 */
const onSWUpdate = (registration: ServiceWorkerRegistration) => {
  pubSubService.sendMessage({
    topic: PubSubTopics.GLOBAL,
    subject: PubSubGlobalSubjects.ServiceWorkerUpdate,
    payload: registration,
  });
};

const rootElement = document.getElementById("root");

const root = createRoot(rootElement!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

/**
 * Leaving this here so it's easy to flip back to the old renderer until we squash
 * all the bugs
 */
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   rootElement
// );

serviceWorkerRegistration.register({ onUpdate: onSWUpdate });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

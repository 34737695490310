import { whooshPrimitives } from "./primitives";
import { whooshTextColors } from "./text";

export type WhooshButtonColors = {
  "button-main-surface-neutral": string;
  "button-main-surface-hover": string;
  "button-main-surface-pressed": string;
  "button-main-text": string;
  "button-main-icon": string;
  "button-alt-surface-neutral": string;
  "button-alt-surface-hover": string;
  "button-alt-surface-pressed": string;
  "button-alt-text": string;
  "button-alt-icon": string;
  "button-white-surface-neutral": string;
  "button-white-surface-hover": string;
  "button-white-surface-pressed": string;
  "button-white-text": string;
  "button-white-icon": string;
  "button-danger-surface-neutral": string;
  "button-danger-surface-hover": string;
  "button-danger-surface-pressed": string;
  "button-danger-text": string;
  "button-danger-icon": string;
  "button-ghost-surface-neutral": string;
  "button-ghost-surface-hover": string;
  "button-ghost-surface-pressed": string;
  "button-ghost-text": string;
  "button-ghost-icon": string;
  "button-disabled-surface-neutral": string;
  "button-disabled-text": string;
  "button-disabled-icon": string;
};

const mainButton = {
  "button-main-surface-neutral": whooshPrimitives.main.primary[500],
  "button-main-surface-hover": whooshPrimitives.main.primary[600],
  "button-main-surface-pressed": whooshPrimitives.main.primary[700],
  "button-main-text": whooshTextColors["text-inverse"],
  "button-main-icon": whooshTextColors["text-inverse"],
};

const altButton = {
  "button-alt-surface-neutral": whooshPrimitives.grayscale[100],
  "button-alt-surface-hover": whooshPrimitives.grayscale[200],
  "button-alt-surface-pressed": whooshPrimitives.grayscale[500],
  "button-alt-text": whooshTextColors["text-primary"],
  "button-alt-icon": whooshTextColors["text-primary"],
};

const whiteButton = {
  "button-white-surface-neutral": whooshPrimitives.grayscale[0],
  "button-white-surface-hover": whooshPrimitives.grayscale[100],
  "button-white-surface-pressed": whooshPrimitives.grayscale[200],
  "button-white-text": whooshTextColors["text-primary"],
  "button-white-icon": whooshTextColors["text-primary"],
};

const dangerButton = {
  "button-danger-surface-neutral": whooshPrimitives.status.error[100],
  "button-danger-surface-hover": whooshPrimitives.status.error[200],
  "button-danger-surface-pressed": whooshPrimitives.status.error[300],
  "button-danger-text": whooshPrimitives.status.error[500],
  "button-danger-icon": whooshPrimitives.status.error[500],
};

const ghostButton = {
  "button-ghost-surface-neutral": "none",
  "button-ghost-surface-hover": "none",
  "button-ghost-surface-pressed": "none",
  "button-ghost-text": whooshTextColors["text-primary"],
  "button-ghost-icon": whooshTextColors["text-primary"],
};

const disabledButton = {
  "button-disabled-surface-neutral": whooshPrimitives.grayscale[500],
  "button-disabled-text": whooshPrimitives.grayscale[800],
  "button-disabled-icon": whooshPrimitives.grayscale[800],
};

export const whooshButtonColors: WhooshButtonColors = {
  ...mainButton,
  ...altButton,
  ...whiteButton,
  ...dangerButton,
  ...ghostButton,
  ...disabledButton,
};

import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const LogOutIcon = (props: IIconProps) => {
  return (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8 4V2H1v12h7v-2M11 4l4 4-4 4M15 8H5"
        fill="none"
      />
    </Icon>
  );
};

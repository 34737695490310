import { whooshPrimitives } from "./primitives";

type WhooshIconColorTokens = {
  "icon-primary": string;
  "icon-inverse": string;
  "icon-main-primary": string;
  "icon-main-secondary": string;
  "icon-disabled": string;
  "icon-status-success": string;
  "icon-status-warning": string;
  "icon-status-error": string;
  "icon-inverse-subtle": string;
};

export const whooshIconColors: WhooshIconColorTokens = {
  "icon-primary": whooshPrimitives.grayscale[1000],
  "icon-inverse": whooshPrimitives.grayscale[0],
  "icon-main-primary": whooshPrimitives.main.primary[500],
  "icon-main-secondary": whooshPrimitives.main.secondary[500],
  "icon-disabled": whooshPrimitives.grayscale[700],
  "icon-status-success": whooshPrimitives.status.success[500],
  "icon-status-warning": whooshPrimitives.status.warning[500],
  "icon-status-error": whooshPrimitives.status.error[500],
  "icon-inverse-subtle": whooshPrimitives.grayscale[700],
};

import { useEffect, useState } from "react";

/**
 * Will return true if online, false if offline
 * will update with new status
 */
export const useIsOnline = () => {
  const [isOnline, updateIsOnline] = useState(
    navigator ? navigator.onLine : false
  );

  const updateStatus = () => {
    updateIsOnline(navigator ? navigator.onLine : false);
  };

  useEffect(() => {
    if (window) {
      window.addEventListener("online", updateStatus);
      window.addEventListener("offline", updateStatus);
    }
  });

  return isOnline;
};

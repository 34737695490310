import { DimensionValue } from "react-native";

export const getModalWidth = (
  size: "small" | "medium" | "large"
): { base: DimensionValue; md: number } => {
  switch (size) {
    case "small":
      return { base: "100%", md: 320 };
    case "medium":
      return { base: "100%", md: 440 };
    case "large":
      return { base: "100%", md: 560 };
    default:
      // default to medium size
      return { base: "100%", md: 440 };
  }
};

import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path, G } from "react-native-svg";

export const NoteStaffIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" {...props}>
    <G
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <Path
        fill="none"
        d="M2 14V2a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1ZM1 4.5h2M6 4.5h5M1 8h2M6 8h5M1 11.5h2M6 11.5h2"
      />
    </G>
  </Icon>
);

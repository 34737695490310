import { useEffect } from "react";
import { Platform } from "react-native";

export const usePreventScroll = ({ isOpen }: { isOpen: boolean }) => {
  useEffect(() => {
    if (Platform.OS === "web") {
      if (isOpen) {
        const originalStyle = {
          overflow: document.body.style.overflow,
          position: document.body.style.position,
          width: document.body.style.width,
          top: document.body.style.top,
          left: document.body.style.left,
          paddingRight: document.body.style.paddingRight,
        };

        const scrollY = window.scrollY;
        const scrollbarWidth =
          window.innerWidth - document.documentElement.clientWidth;
        document.body.style.overflow = "hidden";

        // Add padding to avoid content shift, but only if relevant
        document.body.style.paddingRight = `${scrollbarWidth}px`;

        return () => {
          document.body.style.overflow = originalStyle.overflow;
          document.body.style.paddingRight = originalStyle.paddingRight;
          window.scrollTo(0, scrollY);
        };
      }
    }
  }, [isOpen]);
};

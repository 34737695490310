import { motion } from "framer-motion";
import styled from "styled-components/macro";

const LoadingMessage = styled.h3`
  position: absolute;
  top: -50px;
  max-width: 100%;
  max-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -500%);
`;

const BubbleOne = styled(motion.div)`
  width: 50px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.main.primary[1000]};
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
`;

const BubbleTwo = styled(motion.div)`
  width: 50px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.outdated.neutrals._70};
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
`;

const Subwrapper = styled.div<{ onlyIcon?: boolean }>`
  position: absolute;
  width: 50px;
  height: 50px;
  ${(props) => {
    let returnVal = ``;
    if (!props.onlyIcon) {
      returnVal = `top: 50%;
    margin-top: -25px;
    left: 50%;
    margin-left: -25px;`;
    }
    return returnVal;
  }}

  max-width: 100%;
  max-height: 100%;
`;

interface iWrapperStyles {
  height?: string;
  width?: string;
}
const Wrapper = styled.div<iWrapperStyles>`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "100%")};
  position: relative;
`;

export const UI = {
  BubbleOne,
  BubbleTwo,
  Wrapper,
  Subwrapper,
  LoadingMessage,
};

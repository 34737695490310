import { Icon, IIconProps } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const HelpIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM6.9569 9.96563H8.28448V9.27192C8.28448 8.95397 8.44496 8.75164 8.72215 8.60712C9.10146 8.40478 9.37865 8.24581 9.77255 8.01457C9.79033 8.00416 9.80789 7.9939 9.8252 7.98378C10.4328 7.62872 10.75 7.44336 10.75 6.67051V4.71945C10.75 3.86676 10.0643 3.1875 9.18899 3.1875H6.79642C5.93568 3.1875 5.25 3.86676 5.25 4.71945V5.89008H6.59218V5.00849C6.59218 4.66164 6.86936 4.40149 7.2195 4.40149H8.79509C9.14523 4.40149 9.40783 4.66164 9.40783 5.00849V6.32365C9.40783 6.69941 9.32029 6.81503 8.9118 7.03181C8.68421 7.15322 8.53015 7.23299 8.32987 7.33671C8.19636 7.40585 8.0423 7.48562 7.83223 7.59545C7.16114 7.94231 6.9569 8.2169 6.9569 8.95397V9.96563ZM6.88395 12.8127H8.3866V11.0351H6.88395V12.8127Z"
      fill="currentColor"
    />
  </Icon>
);

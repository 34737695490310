import { whooshPrimitives } from "./primitives";

export type WhooshUserSelectableColorTokens = {
  "surface-user-selectable-colorA-subtlest": string;
  "surface-user-selectable-colorA-subtle": string;
  "text-user-selectable-colorA": string;
  "text-user-selectable-colorA-inverse": string;

  "surface-user-selectable-colorB-subtlest": string;
  "surface-user-selectable-colorB-subtle": string;
  "text-user-selectable-colorB": string;
  "text-user-selectable-colorB-inverse": string;

  "surface-user-selectable-colorC-subtlest": string;
  "surface-user-selectable-colorC-subtle": string;
  "text-user-selectable-colorC": string;
  "text-user-selectable-colorC-inverse": string;

  "surface-user-selectable-colorD-subtlest": string;
  "surface-user-selectable-colorD-subtle": string;
  "text-user-selectable-colorD": string;
  "text-user-selectable-colorD-inverse": string;

  "surface-user-selectable-colorE-subtlest": string;
  "surface-user-selectable-colorE-subtle": string;
  "text-user-selectable-colorE": string;
  "text-user-selectable-colorE-inverse": string;

  "surface-user-selectable-colorF-subtlest": string;
  "surface-user-selectable-colorF-subtle": string;
  "text-user-selectable-colorF": string;
  "text-user-selectable-colorF-inverse": string;
};

export const whooshUserSelectableColors: WhooshUserSelectableColorTokens = {
  "surface-user-selectable-colorA-subtlest":
    whooshPrimitives.userSelectable.A[100],
  "surface-user-selectable-colorA-subtle":
    whooshPrimitives.userSelectable.A[300],
  "text-user-selectable-colorA": whooshPrimitives.userSelectable.A[500],
  "text-user-selectable-colorA-inverse":
    whooshPrimitives.userSelectable.A[1000],

  "surface-user-selectable-colorB-subtlest":
    whooshPrimitives.userSelectable.B[100],
  "surface-user-selectable-colorB-subtle":
    whooshPrimitives.userSelectable.B[300],
  "text-user-selectable-colorB": whooshPrimitives.userSelectable.B[700],
  "text-user-selectable-colorB-inverse":
    whooshPrimitives.userSelectable.B[1000],

  "surface-user-selectable-colorC-subtlest":
    whooshPrimitives.userSelectable.C[100],
  "surface-user-selectable-colorC-subtle":
    whooshPrimitives.userSelectable.C[300],
  "text-user-selectable-colorC": whooshPrimitives.userSelectable.C[500],
  "text-user-selectable-colorC-inverse":
    whooshPrimitives.userSelectable.C[1000],

  "surface-user-selectable-colorD-subtlest":
    whooshPrimitives.userSelectable.D[100],
  "surface-user-selectable-colorD-subtle":
    whooshPrimitives.userSelectable.D[300],
  "text-user-selectable-colorD": whooshPrimitives.userSelectable.D[500],
  "text-user-selectable-colorD-inverse":
    whooshPrimitives.userSelectable.D[1000],

  "surface-user-selectable-colorE-subtlest":
    whooshPrimitives.userSelectable.E[100],
  "surface-user-selectable-colorE-subtle":
    whooshPrimitives.userSelectable.E[300],
  "text-user-selectable-colorE": whooshPrimitives.userSelectable.E[500],
  "text-user-selectable-colorE-inverse":
    whooshPrimitives.userSelectable.E[1000],

  "surface-user-selectable-colorF-subtlest":
    whooshPrimitives.userSelectable.F[100],
  "surface-user-selectable-colorF-subtle":
    whooshPrimitives.userSelectable.F[300],
  "text-user-selectable-colorF": whooshPrimitives.userSelectable.F[500],
  "text-user-selectable-colorF-inverse":
    whooshPrimitives.userSelectable.F[1000],
};

const CheckboxStyles = {
  // Can simply pass default props to change default behaviour of components
  baseStyle: ({ colorScheme }: { colorScheme: string }) => {
    return {
      style: {
        outlineWidth: "0",
        boxShadow: `none`,
      },
      bg: "white",
      borderColor: "grayscale.500",
      borderWidth: 1.5,
      _hover: {
        borderColor: "grayscale.600",
        _disabled: {
          bg: "grayscale.500",
          borderColor: "grayscale.600",
        },
      },
      _checked: {
        style: {
          outlineWidth: "0",
          boxShadow: `none`,
        },
        bg: `main.primary.500`,
        borderColor: `main.primary.500`,
        borderWidth: 1.5,
        _hover: {
          backgroundColor: "main.primary.600",
          borderColor: `main.primary.600`,
          _disabled: {
            backgroundColor: "grayscale.500",
            borderColor: "grayscale.600",
          },
        },
      },
      padding: "3px",
      _icon: {
        color: "white",
        size: "16px",
      },
      _disabled: {
        backgroundColor: "grayscale.500",
        borderColor: "grayscale.600",
        _icon: {
          color: "grayscale.700",
        },
        _hover: {
          backgroundColor: "grayscale.500",
          borderColor: "grayscale.600",
        },
      },
      _focusVisible: {
        _web: {
          style: {
            outlineWidth: "0",
            outlineColor: "transparent",
            outlineStyle: "none",
          },
        },
      },
    };
  },
};

export default CheckboxStyles;

import { createGlobalStyle } from "styled-components/macro";

export const TOUCHGLOBALSTYLES = createGlobalStyle`
    body {
            user-select: none;
        }
`;

export const GLOBALSTYLES = createGlobalStyle`
    @font-face {
    font-family: "Calluna";
    font-style: normal;
    src: './../assets/fonts/CallunaRegular.otf';
    }
    /* We default to border box */
    html {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;

    /* Disables the flash on touching anything that apple devices use */
    -webkit-tap-highlight-color: transparent;
    min-height: 100vh;

    }
    /* This avoids us stomping on any inherited box sizing in anything third party we might pull in */
    *,
    *::before,
    *::after {
        -webkit-box-sizing: inherit;
           -moz-box-sizing: inherit;
                box-sizing: inherit;
    }
    body {
        background-color: ${(props) => props.theme.colors.grayscale[0]};
        font-family: ${(props) => props.theme.typography.mainFont};
        color: ${(props) => props.theme.colors.main.primary[1000]};
        letter-spacing: -0.02em;
        font-size: 14px;
        overflow: hidden;
        min-height: 100vh;

    }

    /**
    *    TYPOGRAPHY
    */
    h1 {
        font-family: ${(props) => props.theme.typography.secondaryFont};
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.02em;
    }
    
    h2 {
        font-family: ${(props) => props.theme.typography.secondaryFont};
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.02em;
    }

    button {
        border: 0px;
    }

    /* This stops certain devices from bumping buttons around */
    button + button {
        margin-left: inherit;
    }

    /* This one's a little controversial - but hide focus lines during prototype */
    :focus {
       outline: none;
    }

    ::-webkit-scrollbar {
        height: 2px;
        width: 2px;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
    /* Optional: show position indicator in red */
    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.main.primary[1000]};
    }

    a {
        text-decoration: none;
    }
`;

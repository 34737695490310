import { Icon, IIconProps } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const StatusWarningIcon = (props: IIconProps) => {
  return (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.173 2.214L.523 6.63a2.5 2.5 0 000 2.573l2.65 4.417a2.5 2.5 0 002.143 1.213h5.368a2.5 2.5 0 002.144-1.213l2.65-4.417a2.5 2.5 0 000-2.573l-2.65-4.416A2.5 2.5 0 0010.684 1H5.316a2.5 2.5 0 00-2.143 1.214zM8.75 4.5a.75.75 0 00-1.5 0v4a.75.75 0 001.5 0v-4zM8 12a1 1 0 100-2 1 1 0 000 2z"
        fill="currentColor"
      />
    </Icon>
  );
};

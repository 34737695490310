import { AnimatePresence, MotiView } from "moti";
import React from "react";

import {
  AnimationDurations,
  AnimationEasings,
} from "../../../theme/animations";
import { Pressable } from "../../primitive";

export type OverlayProps = {
  transparentBackground?: boolean;
  isClosing: boolean;
  onPress?: () => void;
};

export const Overlay = ({
  onPress,
  isClosing,
  transparentBackground,
}: OverlayProps) => {
  return (
    <AnimatePresence>
      {isClosing ? null : (
        <MotiView
          from={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            opacity: {
              type: "timing",
              duration: AnimationDurations.moderate02,
              easing: isClosing
                ? AnimationEasings.exit
                : AnimationEasings.entrance,
            },
          }}
          style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}
        >
          <Pressable
            testID="modal-overlay"
            position={"absolute"}
            left={0}
            right={0}
            top={0}
            bottom={0}
            onPress={onPress}
            backgroundColor={
              transparentBackground ? "transparent" : "surface-overlay"
            }
            opacity={transparentBackground ? 1 : 0.6}
          />
        </MotiView>
      )}
    </AnimatePresence>
  );
};

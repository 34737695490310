import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path, G, Defs, ClipPath } from "react-native-svg";

export const BlockIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" color="#001A19" {...props}>
    <G
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={1.5}
      clipPath="url(#a)"
      fill="none"
    >
      <Path strokeLinecap="round" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Z" />
      <Path d="M13 3 3 13" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Icon>
);

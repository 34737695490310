import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { G, Rect, Defs, ClipPath } from "react-native-svg";

export const EmptyGroupIcon = (props: IIconProps) => {
  return (
    <Icon
      width={160}
      height={160}
      viewBox="0 0 160 160"
      fill="currentColor"
      {...props}
    >
      <G clipPath="url(#clip0_928_2315)">
        <Rect width={160} height={160} rx={80} fill="#F7F7F7" />
        <G filter="url(#filter0_d_928_2315)">
          <Rect x={20} y={48} width={120} height={112} rx={8} fill="#fff" />
        </G>
        <Rect x={36} y={78} width={22} height={6} rx={2} fill="#EDEDED" />
        <Rect x={36} y={92} width={22} height={6} rx={2} fill="#EDEDED" />
        <Rect x={36} y={106} width={22} height={6} rx={2} fill="#F0F0F0" />
        <Rect x={36} y={120} width={22} height={6} rx={2} fill="#F0F0F0" />
        <Rect x={36} y={134} width={22} height={6} rx={2} fill="#F7F7F7" />
        <Rect x={62} y={78} width={45} height={6} rx={2} fill="#EDEDED" />
        <Rect x={62} y={92} width={45} height={6} rx={2} fill="#EDEDED" />
        <Rect x={62} y={106} width={45} height={6} rx={2} fill="#F0F0F0" />
        <Rect x={62} y={120} width={45} height={6} rx={2} fill="#F0F0F0" />
        <Rect x={62} y={134} width={45} height={6} rx={2} fill="#F7F7F7" />
        <Rect x={111} y={78} width={13} height={6} rx={2} fill="#EDEDED" />
        <Rect x={111} y={92} width={13} height={6} rx={2} fill="#EDEDED" />
        <Rect x={111} y={106} width={13} height={6} rx={2} fill="#F0F0F0" />
        <Rect x={111} y={120} width={13} height={6} rx={2} fill="#F0F0F0" />
        <Rect x={111} y={134} width={13} height={6} rx={2} fill="#F7F7F7" />
        <Rect x={36} y={68} width={16} height={4} rx={2} fill="#EDEDED" />
        <Rect x={62} y={68} width={16} height={4} rx={2} fill="#EDEDED" />
        <Rect x={111} y={68} width={8} height={4} rx={2} fill="#EDEDED" />
      </G>
      <Defs>
        <ClipPath id="clip0_928_2315">
          <Rect width={160} height={160} rx={80} fill="#fff" />
        </ClipPath>
      </Defs>
    </Icon>
  );
};

export default EmptyGroupIcon;

import { MotiView } from "moti";
import React, { useEffect, useReducer } from "react";

import {
  AnimationDurations,
  AnimationEasings,
} from "../../../theme/animations";
import { Pressable } from "../../primitive";
import { Toast } from "./Toast";

type ToastWrapperProps = {
  semanticStyle: "success" | "error" | "info" | "warning";
  title?: string;
  description?: string;
  rightButton?: {
    label: string;
    onPress: () => void;
    shouldOnPressDismiss?: boolean;
  };
  duration?: number;
  id: string;
  canDismiss?: boolean | undefined;
  closeToast: (id: string) => void;
  testID?: string;
};

type ToastState = {
  timerState: "Running" | "Paused" | "Stopped";
  timerRemaining: number;
  nextTimerDelay: number;
  startTime: number;
  timerPercent: number;
  timerLength: number;
};

const ToastWrapper = ({
  semanticStyle,
  title,
  description,
  rightButton,
  duration,
  id,
  canDismiss = true,
  closeToast,
  testID,
}: ToastWrapperProps) => {
  const [state, dispatch] = useReducer(timerReducer, {
    timerState: "Running",
    timerRemaining: duration || 5000,
    nextTimerDelay: duration || 5000,
    startTime: Date.now(),
    timerPercent: 0,
    timerLength: duration || 5000,
  });
  /**
   * Handles starting the timer when we need it to start, as well as
   * clearing it out if our state changes
   */
  useEffect(() => {
    if (state.timerState === "Running") {
      const timer = setTimeout(() => {
        if (id) {
          closeToast(id);
        }
      }, state.nextTimerDelay);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [id, closeToast, state.timerState, state.nextTimerDelay]);

  useEffect(() => {
    if (state.timerPercent === 100 && id) {
      closeToast(id);
    }
  }, [state.timerPercent, closeToast, id]);

  /**
   * Handles updating our timerRemaning so that we can tick down our display
   */
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch({ type: "MoveTimer" });
    }, 100);
    return () => clearInterval(interval);
  }, [dispatch]);

  const dismissable = canDismiss !== undefined ? canDismiss : true;

  return (
    <MotiView
      key={id}
      from={{ opacity: 0, top: -8 }}
      animate={{ opacity: 1, top: 0 }}
      exit={{ opacity: 0, top: 8 }}
      transition={{
        top: {
          type: "timing",
          duration: AnimationDurations.fast02,
          easing: AnimationEasings.entrance,
        },
      }}
      exitTransition={{
        top: {
          type: "timing",
          duration: AnimationDurations.fast02,
          easing: AnimationEasings.exit,
        },
      }}
    >
      <Pressable
        onPress={() => {
          dispatch({ type: "Stop" });
        }}
        onHoverIn={() => {
          dispatch({ type: "Pause" });
        }}
        onHoverOut={() => {
          dispatch({ type: "Resume" });
        }}
        paddingLeft={{ base: 0, md: "200" }}
        testID={testID}
      >
        <Toast
          semanticStyle={semanticStyle}
          percentageComplete={state.timerPercent}
          canDismiss={dismissable}
          id={id}
          closeToast={closeToast}
          title={title}
          description={description}
          rightButton={rightButton}
        />
      </Pressable>
    </MotiView>
  );
};

export default ToastWrapper;

const timerReducer = (
  state: ToastState,
  action:
    | { type: "Pause" }
    | { type: "Resume" }
    | { type: "Stop" }
    | { type: "MoveTimer" }
) => {
  switch (action.type) {
    case "Pause":
      const remaining = state.timerRemaining - (Date.now() - state.startTime);
      return {
        ...state,
        timerState:
          state.timerState === "Running" ? "Paused" : state.timerState,
        nextTimerDelay: remaining,
        timerRemaining: remaining,
      } as ToastState;
    case "Resume":
      return {
        ...state,
        timerState:
          state.timerState === "Paused" ? "Running" : state.timerState,
        startTime: state.timerState === "Paused" ? Date.now() : state.startTime,
      } as ToastState;
    case "Stop":
      return {
        ...state,
        timerState: "Stopped",
        timerPercent: 0,
      } as ToastState;
    case "MoveTimer":
      if (state.timerState === "Running") {
        const remainingTime =
          state.timerRemaining - (Date.now() - state.startTime);
        let percent = 100 - (remainingTime / state.timerLength) * 100;
        if (percent > 100) {
          percent = 100;
        }
        return {
          ...state,
          timerPercent: percent,
        };
      }
      return { ...state };

    default:
      return { ...state };
  }
};

import {
  BackgroundColorProps,
  BorderProps,
  LayoutProps,
  OpacityProps,
  PositionProps,
  ShadowProps,
  SpacingProps,
  backgroundColor,
  border,
  composeRestyleFunctions,
  layout,
  opacity,
  position,
  shadow,
  spacing,
  useRestyle,
} from "@shopify/restyle";
import React, { useCallback, useState } from "react";
import {
  GestureResponderEvent,
  NativeMouseEvent,
  NativeSyntheticEvent,
  Pressable as RNPressable,
  TargetedEvent,
} from "react-native";

import { Theme } from "../../../theme/restyle/theme";

type RestyleProps = SpacingProps<Theme> &
  BorderProps<Theme> &
  BackgroundColorProps<Theme> &
  LayoutProps<Theme> &
  OpacityProps<Theme> &
  PositionProps<Theme> &
  ShadowProps<Theme>;

const restyleFunctions = composeRestyleFunctions<Theme, RestyleProps>([
  spacing,
  border,
  backgroundColor,
  layout,
  opacity,
  position,
  shadow,
]);

type RNPressableProps = React.ComponentProps<typeof RNPressable>;

type PressableProps = RestyleProps &
  RNPressableProps & {
    /**
     * Will apply these styles when pressable has focus.
     * Order of application is PRESSED -> HOVER -> FOCUS. So focus will
     * lose to the other states
     */
    _focus?: RestyleProps;
    /**
     * Will apply these styles when hovered.
     */
    _hover?: RestyleProps;
    /**
     * Will apply these styles when the pressable is pressed.
     */
    _pressed?: RestyleProps;
  };

export const Pressable = ({
  children,
  _hover,
  _focus,
  _pressed,
  onHoverIn,
  onHoverOut,
  onPressIn,
  onPressOut,
  onFocus,
  onBlur,
  ...rest
}: PressableProps) => {
  const [hasFocus, setHasFocus] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const propsToInclude = {
    ...rest,
    ...(hasFocus ? _focus ?? {} : {}),
    ...(isHovered ? _hover ?? {} : {}),
    ...(isPressed ? _pressed ?? {} : {}),
  };

  const props = useRestyle<Theme, RestyleProps, RestyleProps>(
    restyleFunctions,
    propsToInclude
  );

  const handleHoverIn = useCallback(
    (e: NativeSyntheticEvent<NativeMouseEvent>) => {
      setIsHovered(true);
      onHoverIn?.(e);
    },
    [onHoverIn]
  );
  const handleHoverOut = useCallback(
    (e: NativeSyntheticEvent<NativeMouseEvent>) => {
      setIsHovered(false);
      onHoverOut?.(e);
    },
    [onHoverOut]
  );
  const shouldUseHoverFunctions = _hover || onHoverIn || onHoverOut;

  const handlePressIn = useCallback(
    (e: GestureResponderEvent) => {
      setIsPressed(true);
      onPressIn?.(e);
    },
    [onPressIn]
  );
  const handlePressOut = useCallback(
    (e: GestureResponderEvent) => {
      setIsPressed(false);
      onPressOut?.(e);
    },
    [onPressOut]
  );
  const shouldUsePressFunctions = _pressed || onPressIn || onPressOut;

  const handleFocus = useCallback(
    (e: NativeSyntheticEvent<TargetedEvent>) => {
      setHasFocus(true);
      onFocus?.(e);
    },
    [onFocus]
  );
  const handleBlur = useCallback(
    (e: NativeSyntheticEvent<TargetedEvent>) => {
      setHasFocus(false);
      onBlur?.(e);
    },
    [onBlur]
  );
  const shouldUseFocusFunctions = _focus || onFocus || onBlur;

  return (
    <RNPressable
      {...props}
      onHoverIn={shouldUseHoverFunctions ? handleHoverIn : undefined}
      onHoverOut={shouldUseHoverFunctions ? handleHoverOut : undefined}
      onPressIn={shouldUsePressFunctions ? handlePressIn : undefined}
      //@ts-ignore -- necessary to catch mouse as well as touch
      onMouseDown={shouldUsePressFunctions ? handlePressIn : undefined}
      //@ts-ignore
      onMouseUp={shouldUsePressFunctions ? handlePressOut : undefined}
      onPressOut={shouldUsePressFunctions ? handlePressOut : undefined}
      onFocus={shouldUseFocusFunctions ? handleFocus : undefined}
      onBlur={shouldUseFocusFunctions ? handleBlur : undefined}
    >
      {children}
    </RNPressable>
  );
};

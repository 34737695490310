import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const ChevronMiniRight = (props: IIconProps) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      fill="currentColor"
      d="m9.646 8.354-2.792 2.792A.5.5 0 0 1 6 10.793V5.207a.5.5 0 0 1 .854-.353l2.792 2.792a.5.5 0 0 1 0 .708Z"
    />
  </Icon>
);

import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const EventsIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 16 16" fill="none" fillOpacity="0" {...props}>
    <Path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.046 15 2 3.5s.305-1 1.828-1.333c2.476-.542 3.86 1.416 6.094.583C11.841 2.034 12.968 1 12.968 1l2.031 7s-1.219 1.167-3.047 1.5C9.892 9.876 9.046 8.417 6 9"
      fill="none"
    />
  </Icon>
);

import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path, Circle } from "react-native-svg";

export const FilterIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" color="#001A19" {...props}>
    <Path
      stroke="#001A19"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M1 5h7M7 11h8M1 11h2M12 5h3"
    />
    <Circle
      cx={10}
      cy={5}
      r={2}
      stroke="#001A19"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      fill="none"
    />
    <Circle
      cx={5}
      cy={11}
      r={2}
      stroke="#001A19"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      fill="none"
    />
  </Icon>
);

import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const DollarAltIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.66669 2.21875C8.09816 2.21875 8.44794 2.56853 8.44794 3V3.46875H8.91669C10.0385 3.46875 10.9479 4.37817 10.9479 5.5C10.9479 5.93147 10.5982 6.28125 10.1667 6.28125C9.73521 6.28125 9.38544 5.93147 9.38544 5.5C9.38544 5.24112 9.17557 5.03125 8.91669 5.03125H7.66669H6.92154C6.38383 5.03125 5.94794 5.46715 5.94794 6.00485C5.94794 6.45161 6.25199 6.84103 6.68541 6.94939L9.02693 7.53477C10.1559 7.81701 10.9479 8.83141 10.9479 9.99515C10.9479 11.3837 9.83194 12.5117 8.44794 12.531V13C8.44794 13.4315 8.09816 13.7812 7.66669 13.7812C7.23521 13.7812 6.88544 13.4315 6.88544 13V12.5312H6.41669C5.29486 12.5312 4.38544 11.6218 4.38544 10.5C4.38544 10.0685 4.73521 9.71875 5.16669 9.71875C5.59816 9.71875 5.94794 10.0685 5.94794 10.5C5.94794 10.7589 6.1578 10.9688 6.41669 10.9688H7.66669H8.41183C8.94954 10.9688 9.38544 10.5329 9.38544 9.99515C9.38544 9.54839 9.08138 9.15897 8.64797 9.05061L6.30644 8.46523C5.17746 8.18299 4.38544 7.16859 4.38544 6.00485C4.38544 4.61626 5.50143 3.48831 6.88544 3.469V3C6.88544 2.56853 7.23521 2.21875 7.66669 2.21875Z"
      fill="currentColor"
    />
  </Icon>
);

import { IIconProps, Icon, useToken } from "native-base";
import React from "react";
import {
  Defs,
  LinearGradient,
  Stop,
  Mask,
  Circle,
  Rect,
} from "react-native-svg";

export const LoadingSpinnerIcon = (props: IIconProps) => {
  //this is required if we have multiple instances of a loading spinner on one page, because without adding a numbered key the SVG's will cache and share colors
  // I chose the random number approach over passing keys in to avoid prop drilling.
  const randomNum = Math.floor(Math.random() * 1000);
  //i dont know why but my linter refuses to let this type be seen as a string...
  // if I dont force the type it throws a typescript error about not allowing null or undefined...
  // The icon works though.
  const colorString =
    props.color !== null &&
    props.color !== undefined &&
    !!props.color &&
    typeof props.color === "string"
      ? props.color
      : "grayscale.1000";
  const color = useToken("colors", colorString);

  return (
    <Icon width="16" height="16" viewBox="0 0 16 16" {...props}>
      <Defs>
        <LinearGradient
          id={`grad1-${randomNum}`}
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
        >
          <Stop offset="0%" stopColor={color} />
          <Stop offset="100%" stopColor={color} stopOpacity={0} />
        </LinearGradient>
        <LinearGradient
          id={`grad2-${randomNum}`}
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
        >
          <Stop offset="0%" stopColor={color} stopOpacity={0} />
          <Stop offset="100%" stopColor={color} stopOpacity={0.254} />
        </LinearGradient>
        <Mask id={`ring-mask-${randomNum}`}>
          <Circle cx="8" cy="8" r="6" fill="white" />
          <Circle cx="8" cy="8" r="3.5" fill="black" />
        </Mask>
      </Defs>
      <Rect
        width="8"
        height="16"
        fill={`url(#grad1-${randomNum})`}
        mask={`url(#ring-mask-${randomNum})`}
      />
      <Rect
        x="8"
        width="8"
        height="16"
        fill={`url(#grad2-${randomNum})`}
        mask={`url(#ring-mask-${randomNum})`}
      />
    </Icon>
  );
};

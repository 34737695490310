import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path, G, Svg, Circle } from "react-native-svg";

export const CircleBarIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" {...props}>
    <Svg fill="none">
      <G id="Icon/16px/Close-Small">
        <Circle
          id="Ellipse 247"
          cx="8"
          cy="8"
          r="6"
          stroke="currentColor"
          strokeWidth={1.5}
          strokeLinecap="round"
        />
        <Path
          id="Vector 1"
          d="M6 8H10"
          stroke="currentColor"
          strokeWidth={1.5}
          strokeLinecap="round"
        />
      </G>
    </Svg>
  </Icon>
);

import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { G, Path, Defs, ClipPath } from "react-native-svg";

export const ReloadIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 17 16" {...props}>
    <G
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      clipPath="url(#a)"
      fill="none"
    >
      <Path d="M5.5 3.5h6a2 2 0 0 1 2 2v4m0 0-2-2h4l-2 2ZM11.5 12.5h-6a2 2 0 0 1-2-2v-4m0 0 2 2h-4l2-2Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#no" d="M.5 0h16v16H.5z" />
      </ClipPath>
    </Defs>
  </Icon>
);

export default ReloadIcon;

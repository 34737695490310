import { motion } from "framer-motion";
import styled from "styled-components/macro";

import { DesignSize, WhooshVariants } from "@design-system/theme";

import { ButtonShape } from ".";

export interface iStyledButtonProps {
  variant: WhooshVariants;
  size?: DesignSize;
  shape: ButtonShape;
  ignoreIconStyles?: boolean;
}
const StyledButton = styled(motion.button)<iStyledButtonProps>`
  border-radius: ${(props) =>
    props.shape === ButtonShape.PILL ? "100px" : "8px"};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  background-color: ${(props) =>
    props.theme.variantStyles[props.variant].backgroundColor};
  color: ${(props) => props.theme.variantStyles[props.variant].color};
  text-align: center;

  :focus {
    outline: 3px solid #b3d7ff;
  }

  ${(props) => {
    if (props.variant === WhooshVariants.SECONDARY) {
      return `
        border: 2px solid ${props.theme.variantStyles[props.variant].color};
        color: ${props.theme.colors.main.primary[500]};
      `;
    }
    return ``;
  }}

  ${(props) => {
    if (props.variant === WhooshVariants.TERTIARY) {
      return `
        border: 1px solid ${
          props.theme.variantStyles[props.variant].borderColor
        };
      `;
    }
    return ``;
  }}

  /**
    Need to handle SVG
   */
    svg {
    max-width: 100%;
    max-height: 100%;
    ${(props) => {
      let returnVal = ``;
      if (!props.ignoreIconStyles) {
        returnVal = ` 
                stroke: ${props.theme.variantStyles[props.variant].color};
                fill: ${props.theme.variantStyles[props.variant].color};
                path {
                  stroke: ${props.theme.variantStyles[props.variant].color};
                }
              `;
      }
      return returnVal;
    }}

    margin-right: 12px;
    width: 20px;
    height: 20px;
  }
  /**
    Size overrides
    This prop could very well be removed as it seems we don't have consistent button sizes and will
    likely need to override them at point of use anyhow
  */
  padding: 16px 24px;
  height: ${(props) => (props.size === DesignSize.LG ? "56px" : "40px")};
  font-size: 13px;
  /**
    Disabled Styles
  */
  ${(props) => {
    if (props.disabled) {
      return `
        opacity: 0.5;
        cursor: not-allowed;
      `;
    }
    return ``;
  }}
`;
const LeftIconWrap = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UI = {
  StyledButton,
  LeftIconWrap,
};

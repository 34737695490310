import React from "react";

import { Button } from "../../controls/Button/Button";
import {
  AlertIcon,
  InfoIcon,
  CheckIcon,
  CloseIcon,
} from "../../icons_v2/general";
import { Box, HStack, VStack, Text, Pressable } from "../../primitive";
import { ToastProps } from "./types";

export const Toast = ({
  semanticStyle,
  title,
  description,
  rightButton,
  percentageComplete = 0,
  id,
  canDismiss,
  closeToast,
}: ToastProps) => {
  const iconProps = {
    size: "16px",
    color: "grayscale.1000",
    zIndex: 20,
  };
  return (
    <Box
      borderRadius="sm"
      flexDirection={"row"}
      width={400}
      maxWidth="90%"
      //@ts-ignore
      fsClass="fs-unmask"
      shadowColor={"black"}
      shadowOffset={{ width: 0, height: 4 }}
      shadowOpacity={0.08}
      shadowRadius={16}
      elevation={2}
      testID={id}
    >
      <Box
        testID="toast-container"
        borderTopLeftRadius="sm"
        borderBottomLeftRadius="sm"
        borderTopRightRadius={0}
        borderBottomRightRadius={0}
        backgroundColor={
          semanticStyle === "info"
            ? "surface-secondary"
            : `surface-status-${semanticStyle}-subtle`
        }
        px={2}
        overflow="hidden"
        justifyContent="center"
        flexGrow={0}
        flexBasis={32}
        flexShrink={0}
      >
        <Box
          position="absolute"
          left={0}
          right={0}
          height={`${percentageComplete}%`}
          bottom={0}
          backgroundColor={
            semanticStyle === "info"
              ? "grayscale-700"
              : `icon-status-${semanticStyle}`
          }
          zIndex={10}
        />
        {semanticStyle === "error" ? (
          <AlertIcon {...iconProps} testID="error-icon" />
        ) : null}
        {semanticStyle === "warning" ? (
          <AlertIcon {...iconProps} testID="warning-icon" />
        ) : null}
        {semanticStyle === "success" ? (
          <CheckIcon {...iconProps} testID="success-icon" />
        ) : null}
        {semanticStyle === "info" ? (
          <InfoIcon {...iconProps} testID="info-icon" />
        ) : null}
      </Box>
      <Box
        padding="200"
        borderWidth={0}
        borderTopRightRadius="md"
        borderBottomRightRadius="md"
        backgroundColor="grayscale-0"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        position="relative"
        flex={1}
      >
        <HStack
          flex={1}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap="050"
        >
          <VStack flex={1} gap={1}>
            {title ? (
              <Text testID="toast-title-container" variant="mainSmallSemiBold">
                {title}
              </Text>
            ) : null}
            {description ? (
              <Text color={"text-secondary"} variant="mainTiny">
                {description}
              </Text>
            ) : null}
          </VStack>
          {rightButton ? (
            <VStack>
              <Button
                variant="ghost"
                size="xs"
                onPress={() => {
                  rightButton.onPress();
                  id && rightButton.shouldOnPressDismiss && closeToast?.(id);
                }}
              >
                {rightButton.label}
              </Button>
            </VStack>
          ) : null}
          {canDismiss && id ? (
            <VStack>
              <Pressable
                onPress={() => closeToast?.(id)}
                justifyContent="center"
                accessibilityLabel="Toast Close Button"
              >
                <CloseIcon size="16px" />
              </Pressable>
            </VStack>
          ) : null}
        </HStack>
      </Box>
    </Box>
  );
};

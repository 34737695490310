import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path, G } from "react-native-svg";

export const MemberNoteIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" {...props}>
    <G id="Icon/16px/Note-Staff">
      <Path
        id="Vector 39"
        d="M13 10V4C13 3.44772 12.5523 3 12 3H4C3.44772 3 3 3.44772 3 4V12C3 12.5523 3.44772 13 4 13H10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <Path
        id="Vector 320"
        d="M6 6.5H10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        id="Vector 321"
        d="M6 9H7"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        id="Vector 40"
        d="M10 13V10.5C10 10.2239 10.2239 10 10.5 10H13L10 13Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
  </Icon>
);

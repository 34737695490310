import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const DollarIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 3H6.5A2.5 2.5 0 0 0 4 5.5v0A2.5 2.5 0 0 0 6.5 8h3a2.5 2.5 0 0 1 2.5 2.5v0A2.5 2.5 0 0 1 9.5 13H4M8 1v14"
    />
  </Icon>
);

import { Subject } from "rxjs";

const subject = new Subject<iPubSubEvent>();

export enum PubSubTopics {
  TeeSheet = "teeSheet",
  GLOBAL = "global",
}

export enum PubSubGlobalSubjects {
  ServiceWorkerUpdate = "serviceWorkerUpdate",
}

export enum PubSubTeeSheetSubjects {
  QueueDrawer = "queueDrawer",
  TimeBarScroll = "timeBarScroll",
  TSARowScroll = "TSARowScroll",
  OpenChatThread = "openChatThread",
}

export interface iPubSubEvent {
  topic: PubSubTopics;
  subject: PubSubTeeSheetSubjects | PubSubGlobalSubjects;
  payload: any;
}

/**
 * Hyper minimal pub/sub service backed by rxjs.
 */
export const pubSubService = {
  sendMessage: (event: iPubSubEvent) => subject.next(event),
  onMessage: () => subject.asObservable(),
};

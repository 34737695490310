import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const SquareIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      fill="#001A19"
      stroke="none"
      d="M12 2H4c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2Zm-.182 9.182c0 .35-.286.635-.636.635H4.818a.637.637 0 0 1-.636-.635V4.82c0-.35.286-.636.636-.636h6.364c.35 0 .636.286.636.636v6.363Zm-2.181-1.91c0 .201-.164.364-.363.364H6.728a.364.364 0 0 1-.363-.363V6.728c0-.2.163-.364.363-.364h2.546c.2 0 .363.164.363.364v2.545Z"
    />
  </Icon>
);

import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const LeftChevronIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 16 16" fill="black" {...props}>
    <Path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m6.354 7.646 2.792-2.792a.5.5 0 0 1 .854.353v5.586a.5.5 0 0 1-.854.353L6.354 8.354a.5.5 0 0 1 0-.708Z"
    />
  </Icon>
);

import { IIconProps, Icon } from "native-base";
import React from "react";
import { Path } from "react-native-svg";

export const RedirectIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      d="M7 4H2V14H12V9"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <Path
      d="M9 2H14V7"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M14 2L7 9"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

const RadioStyles = {
  // Can simply pass default props to change default behaviour of components.
  defaultProps: {
    variant: "primary",
    textFlip: false,
  },
  baseStyle: ({ colorScheme }: { colorScheme: string }) => ({
    _focusVisible: {
      _web: {
        style: {
          outlineWidth: "0px",
        },
      },
    },
    _hover: {
      borderColor: `grayscale.500`,
    },
    borderColor: `grayscale.500`,
    bg: "transparent",
    _text: {
      color: `primary.1000`,
      variant: "mainBaseMedium",
    },
    _checked: {
      borderColor: `surface-main-primary-neutral`,
      borderWidth: "2",
      _icon: {
        color: "surface-main-primary-neutral",
      },
      _hover: {
        borderColor: `surface-main-primary-neutral`,
        borderWidth: "2",
        _icon: {
          color: "surface-main-primary-neutral",
        },
      },
    },
  }),
};

export default RadioStyles;

import SentryFullStory from "@sentry/fullstory";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { getEnvironment } from "../Environment";

let sentryEnabled = false;

export interface iIdentityProps {
  email: string | undefined;
  username: string;
  facility: string;
  masqueraderEmail: string;
}
export const identify = (props: iIdentityProps) => {
  if (sentryEnabled) {
    Sentry.setUser(props);
  }
};

export const init = () => {
  if (process.env.NODE_ENV === "production") {
    const integrations = [];
    integrations.push(new Integrations.BrowserTracing());
    const { FULLSTORY_TOKEN, DEPLOY_ENV } = getEnvironment();
    if (FULLSTORY_TOKEN) {
      integrations.push(new SentryFullStory(FULLSTORY_TOKEN));
    }
    Sentry.init({
      dsn: "https://77f7e8c0fa69414989626c37e6668f68@o552323.ingest.sentry.io/5697380",
      integrations: integrations,
      environment: DEPLOY_ENV,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
    sentryEnabled = true;
  }
};

import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const TrashIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" color="currentColor" {...props}>
    <Path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      fill="none"
      d="M2.5 5h11M5.257 5V3.371A1.371 1.371 0 0 1 6.63 2H9.37a1.372 1.372 0 0 1 1.372 1.371V5M12 5v7.629A1.371 1.371 0 0 1 10.629 14H5.37A1.371 1.371 0 0 1 4 12.629V5h8Z"
    />
  </Icon>
);

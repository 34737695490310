import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path, Svg } from "react-native-svg";

export const CalendarCancelIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" {...props}>
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <Path
        id="Vector"
        d="M15 7C15 5.41621 15 3.44444 15 3.44444C15 2.6467 14.3036 2 13.4444 2H2.55556C1.69645 2 1 2.6467 1 3.44444V13.5556C1 14.3533 1.69645 15 2.55556 15H7"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        id="Vector_2"
        d="M11 1V4"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        id="Vector_3"
        d="M5 1V4"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        id="Vector_4"
        d="M1 7H15"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        id="Vector 213"
        d="M15 11L11 15"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        id="Vector 214"
        d="M11 11L15 15"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  </Icon>
);

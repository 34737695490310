import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path, G } from "react-native-svg";

export const NoteMembersIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" {...props}>
    <G
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <Path d="m8 15 2.707-2.707a1 1 0 0 1 .707-.293H13a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h4M5 8.5h3.5M5 5.5h6" />
    </G>
  </Icon>
);

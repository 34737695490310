import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const CheckIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.5}
      strokeLinejoin="round"
      d="M3 8L6 11L13 4"
      fill="none"
    />
  </Icon>
);

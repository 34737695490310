import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const EditIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" {...props}>
    <Path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.941 9.559 10.91 2.59a2 2 0 1 1 2.828 2.828L6.77 12.387l-4.462 1.634L3.94 9.559ZM10 3.5l2.828 2.828M4.429 9.071 7.257 11.9"
      fill="none"
    />
  </Icon>
);

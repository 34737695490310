import {
  SpacingProps,
  composeRestyleFunctions,
  spacing,
  useRestyle,
  TypographyProps,
  typography,
  color,
  ColorProps,
  border,
  BorderProps,
  LayoutProps,
  layout,
} from "@shopify/restyle";
import React, { useCallback } from "react";
import { TextInput } from "react-native";

import { Theme } from "../../../theme/restyle/theme";
import { BaseInputProps, InputType, OnChangeType } from "./types";

type RestyleTypography = TypographyProps<Theme> & {
  textAlign?: "left" | "right" | "center";
  textAlignVertical?: "top" | "bottom" | "center";
  verticalAlign?: "top" | "bottom" | "auto" | "middle" | undefined;
};

type RestyleProps = SpacingProps<Theme> &
  RestyleTypography &
  ColorProps<Theme> &
  BorderProps<Theme> &
  LayoutProps<Theme>;

export type PrimativeInputProps<T extends InputType> = RestyleProps &
  BaseInputProps<T>;

const restyleFunctions = composeRestyleFunctions<Theme, RestyleProps>([
  spacing,
  typography,
  color,
  border,
  layout,
]);

export const Input = <T extends InputType>({
  testID,
  placeholder,
  value,
  type,
  inputRef,
  onChange,
  name,
  placeholderTextColor,
  keyboardType,
  ...restProps
}: PrimativeInputProps<T>) => {
  const memoizedOnChange = useCallback(
    (val: string | number) => {
      if (type === "password" || type === "text" || !type) {
        const stringOnChange = onChange as OnChangeType<"text">;
        typeof val === "string"
          ? stringOnChange(val)
          : stringOnChange(val.toString());
      } else if (type === "number") {
        const numberOnChange = onChange as OnChangeType<"number">;
        typeof val === "number"
          ? numberOnChange(val)
          : numberOnChange(isNaN(parseInt(val)) ? 0 : parseInt(val));
      }
    },
    [onChange, type]
  );

  const restyleProps = useRestyle(restyleFunctions, { ...restProps });

  return (
    <TextInput
      testID={testID}
      placeholder={placeholder}
      value={value?.toString() ?? ""}
      onChangeText={memoizedOnChange}
      placeholderTextColor={placeholderTextColor}
      aria-label={name}
      ref={inputRef}
      keyboardType={keyboardType}
      {...restyleProps}
    />
  );
};

export * from "./types";

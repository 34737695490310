import React from "react";

import { Stack, StackProps } from "./Stack";

export const Center = (props: StackProps) => {
  return (
    <Stack
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      {...props}
    />
  );
};

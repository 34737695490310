import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const LogoMonoChrome = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 40 40" color="currentColor" {...props}>
    <Path
      fill="#002726"
      fillRule="evenodd"
      d="M28.949 6.982c1.35-1.237 2.153-1.057 2.617-.57.519.545.553 1.362.207 2.02l-1.96 3.704H10.188l-1.96-3.705c-.346-.654-.314-1.474.204-2.019.465-.487 1.266-.667 2.618.57 2.187 2.002 3.47 2.482 4.756 2.231.6-.117 1.12-.474 1.437-.97.467-.73 1.147-2.079 1.552-4.201.08-.411.34-.785.745-.956.142-.058.298-.093.46-.085.16-.008.316.025.458.085.405.171.666.545.745.956.275 1.438.663 2.515 1.026 3.286a3.302 3.302 0 0 0 3.04 1.874c1.102-.019 1.878-.572 3.68-2.22ZM22.462 14.8l2.306 13.68L26.84 14.8h4.757L27.626 35h-5.153l-.014-.08-2.488-13.066L17.501 35h-5.156l-3.94-20.2h4.988l2.12 13.767 2.232-13.766h4.717Z"
      clipRule="evenodd"
    />
  </Icon>
);

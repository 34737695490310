import { t } from "@lingui/macro";
import { useAtom } from "jotai";

import { PopUpModal } from "@whoosh/design-system/src/components/overlay/PopUpModal";
import { VStack, Text } from "@whoosh/design-system/src/components/primitive";

import { GlobalConfirmationModalDataAtom } from "../atoms/GlobalConfirmationModal";

export const GlobalConfirmationModal = () => {
  const [data, setModalData] = useAtom(GlobalConfirmationModalDataAtom) ?? {};

  const isModalOpen = !!data;

  const { message, title, onAction } = data ?? {};

  return (
    <PopUpModal
      isOpen={isModalOpen}
      upperTitle={t`${title}`}
      cancelText={t`Cancel`}
      confirmText={t`Complete`}
      onClose={(confirm: boolean) => {
        if (confirm) {
          if (onAction) {
            onAction(confirm);
            setModalData(null);
          }
        } else {
          setModalData(null);
        }
      }}
      content={
        <VStack>
          <Text color="text-secondary">{t`${message}`}</Text>
        </VStack>
      }
    />
  );
};

import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const ChevronMiniDown = (props: IIconProps) => (
  <Icon viewBox="0 0 16 16" fill="currentColor" {...props}>
    <Path
      fill="currentColor"
      d="M7.646 9.646 4.854 6.854A.5.5 0 0 1 5.207 6h5.586a.5.5 0 0 1 .353.854L8.354 9.646a.5.5 0 0 1-.708 0Z"
    />
  </Icon>
);

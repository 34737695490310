import React, { useCallback, useContext } from "react";

import { ToastContext } from "./WhooshToastProvider";
import { ShowToastProps } from "./types";

export const useToast = () => {
  const context = useContext(ToastContext);

  const { add, remove } = context ?? {
    add: (toast: ShowToastProps) => {},
    remove: (toastId: string) => {},
  };

  const closeToast = useCallback(
    (toastId: string) => {
      remove(toastId);
    },
    [remove]
  );

  const showToast = React.useCallback(
    (args: ShowToastProps) => {
      add(args);
    },
    [add]
  );

  return { showToast, closeToast };
};

import { atom } from "jotai";

export type ConfirmationModalData = {
  message: string;
  title: string;
  onAction: (val: boolean) => void;
};

export const GlobalConfirmationModalDataAtom =
  atom<ConfirmationModalData | null>(null);

import { Icon, IIconProps } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const StarSmallIcon = (props: IIconProps) => {
  return (
    <Icon
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="currentColor"
      {...props}
    >
      <Path
        d="M7.638 3.275a.375.375 0 01.724 0l.92 2.964c.05.164.196.276.361.276h2.976c.368 0 .522.494.223.72l-2.407 1.832a.41.41 0 00-.138.446l.92 2.964c.113.367-.288.672-.586.445L8.224 11.09a.367.367 0 00-.448 0L5.37 12.922c-.298.227-.7-.078-.585-.445l.92-2.964a.41.41 0 00-.14-.446L3.159 7.235c-.299-.226-.145-.72.223-.72h2.976c.165 0 .31-.112.362-.276l.92-2.964z"
        fill="#001A19"
      />
    </Icon>
  );
};

export default StarSmallIcon;

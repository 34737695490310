import { UI } from "./ui";

interface iLoadingProps {
  height?: string;
  width?: string;
  onlyIcon?: boolean;
  style?: React.CSSProperties;
  message?: string;
}
const Loading = (props: iLoadingProps) => {
  return (
    <UI.Wrapper {...props} data-testid="loading-spinner-wrapper">
      {props.message && <UI.LoadingMessage>{props.message}</UI.LoadingMessage>}
      <UI.Subwrapper onlyIcon={props.onlyIcon}>
        <UI.BubbleOne
          initial={{ scale: 1 }}
          animate={{ scale: 0 }}
          transition={{
            repeat: Infinity,
            repeatType: "reverse",
            duration: 0.8,
          }}
        />
        <UI.BubbleTwo
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            repeat: Infinity,
            repeatType: "reverse",
            duration: 0.8,
          }}
        />
      </UI.Subwrapper>
    </UI.Wrapper>
  );
};

export default Loading;

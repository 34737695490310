import { extendTheme } from "native-base";
import { Platform } from "react-native";

import { ButtonStyles } from "../components/controls/Button/ButtonStyles";
import CheckboxStyles from "../components/controls/Checkbox/CheckboxStyles";
import RadioStyles from "../components/controls/RadioList/RadioStyles";
import SwitchStyles from "../components/controls/Switch/SwitchStyles";
import {
  whooshPrimitives,
  whooshIconColors,
  whooshTextColors,
  whooshSurfaceColors,
  whooshButtonColors,
} from "./colors";
import { whooshOutlineColors } from "./colors/outline";
import { whooshUserSelectableColors } from "./colors/userSelectable";
import { shadowOverrides } from "./shadows";

/**
 * These types exact match our figma color keys. They don't all define the same keys.
 * This is done so that we DO NOT define keys that aren't actually defined, and cause engineers
 * to accidentally use the wrong key. It also ensures intellisense only hints actually defined colors
 */

export const WhooshTheme = extendTheme({
  colors: {
    ...whooshPrimitives,
    ...whooshTextColors,
    ...whooshSurfaceColors,
    ...whooshIconColors,
    ...whooshButtonColors,
    ...whooshOutlineColors,
    ...whooshUserSelectableColors,
  },
  breakpoints: {
    base: 0,
    xs: 368,
    sm: 480,
    md: 768,
    agendaSplit: 834,
    eventCardColumn: 990,
    lg: 1100,
    xl: 1280,
  },
  fontConfig: {
    Lora: {
      500: {
        normal: "Lora_500Medium",
      },
    },
    Times: {
      100: {
        normal: "Times-Regular",
      },
      200: {
        normal: "Times-Regular",
      },
      300: {
        normal: "Times-Regular",
      },
      400: {
        normal: "Times-Regular",
      },
      500: {
        normal: "Times-Regular",
      },
      600: {
        normal: "Times-Semibold",
      },
      700: {
        normal: "Times-Semibold",
      },
      800: {
        normal: "Times-Semibold",
      },
      900: {
        normal: "Times-Semibold",
      },
    },
    Inter: {
      100: {
        normal: "Inter_200ExtraLight",
      },
      200: {
        normal: "Inter_200ExtraLight",
      },
      300: {
        normal: "Inter_500Medium",
      },
      400: {
        normal: "Inter_400Regular",
      },
      500: {
        normal: "Inter_500Medium",
      },
      600: {
        normal: "Inter_600SemiBold",
      },
      700: {
        normal: "Inter_600SemiBold",
      },
      800: {
        normal: "Inter_600SemiBold",
      },
    },
  },
  fonts: {
    heading: "Times",
    body: "Inter",
    mono: "Inter",
    serif: "Lora_500Medium",
  },
  fontSizes: {
    xs: 11,
    sm: 12,
    md: 13,
    lg: 14,
    xl: 16,
    "2xl": 20,
    "3xl": 24,
    "4xl": 32,
    "5xl": 40,
  },
  lineHeights: {
    xs: "16",
    sm: "16",
    md: "20",
    lg: "20",
    xl: "24",
    "2xl": "28",
    "3xl": "32",
    "4xl": "40",
    "5xl": "52",
  },

  shadows: {
    ...shadowOverrides,
  },
  components: {
    Button: {
      ...ButtonStyles,
    },
    Checkbox: {
      ...CheckboxStyles,
    },
    Radio: {
      ...RadioStyles,
    },
    TextArea: {
      baseStyle: {
        _web: {
          outlineWidth: 0,
          fontSize: 100,
        },
        _hover: {
          borderColor: "surface-dividers",
        },
        _focus: {
          backgroundColor: "white",
        },
      },
    },
    Text: {
      baseStyle: {
        color: "text-primary",
        maxFontSizeMultiplier: 2,
      },
      variants: {
        mainTitle2: () => {
          return {
            fontWeight: 500,
            fontFamily:
              Platform.OS === "web"
                ? "Inter_500Medium, Inter"
                : "Inter_500Medium",
            fontSize: "28px",
            lineHeight: "32px",
            letterSpacing: 28 * -0.02,
          };
        },
        mainHeading: () => {
          return {
            fontFamily:
              Platform.OS === "web"
                ? "Inter_500Medium, Inter"
                : "Inter_500Medium",
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "24px",
            letterSpacing: 18 * -0.03,
          };
        },
        mainBaseMedium: () => {
          return {
            fontFamily:
              Platform.OS === "web"
                ? "Inter_500Medium, Inter"
                : "Inter_500Medium",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "18px",
            letterSpacing: 14 * -0.02,
          };
        },
        mainBaseSemiBold: () => {
          return {
            fontSize: "14px",
            fontWeight: 500,
            fontFamily:
              Platform.OS === "web"
                ? "Inter_600SemiBold, Inter"
                : "Inter_600SemiBold",
            lineHeight: "18px",
            letterSpacing: 14 * -0.02,
          };
        },
        mainSmall: () => {
          return {
            fontSize: "13px",
            fontWeight: 500,
            fontFamily:
              Platform.OS === "web"
                ? "Inter_600SemiBold, Inter"
                : "Inter_600SemiBold",
            lineHeight: "16px",
            letterSpacing: 13 * -0.02,
          };
        },
        mainTiny: () => {
          return {
            fontSize: "11px",
            fontWeight: 500,
            fontFamily:
              Platform.OS === "web"
                ? "Inter_600SemiBold, Inter"
                : "Inter_600SemiBold",
            lineHeight: "16px",
            letterSpacing: 11 * -0.02,
          };
        },
        mainMicro: () => {
          return {
            fontSize: "10px",
            fontWeight: 500,
            fontFamily:
              Platform.OS === "web"
                ? "Inter_600SemiBold, Inter"
                : "Inter_600SemiBold",
            lineHeight: "12px",
            letterSpacing: 0,
          };
        },
        serifTitle1: () => {
          return {
            fontSize: "40px",
            lineHeight: "44px",
            maxFontSizeMultiplier: 1.5,
            letterSpacing: 40 * -0.04,
            fontFamily:
              Platform.OS === "web" ? "Lora_500Medium, Lora" : "Lora_500Medium",
          };
        },
        serifTitle2: () => {
          return {
            fontSize: "32px",
            lineHeight: "36px",
            maxFontSizeMultiplier: 1.5,
            letterSpacing: 32 * -0.04,
            fontFamily:
              Platform.OS === "web" ? "Lora_500Medium, Lora" : "Lora_500Medium",
          };
        },
        serifTitle3: () => {
          return {
            fontSize: "24px",
            lineHeight: "28px",
            maxFontSizeMultiplier: 1.5,
            letterSpacing: 24 * -0.04,
            fontFamily:
              Platform.OS === "web" ? "Lora_500Medium, Lora" : "Lora_500Medium",
          };
        },
        serifHeading: () => {
          return {
            fontSize: "20px",
            lineHeight: "24px",
            maxFontSizeMultiplier: 1.5,
            letterSpacing: 20 * -0.02,
            fontFamily:
              Platform.OS === "web" ? "Lora_500Medium, Lora" : "Lora_500Medium",
          };
        },
        serifBase: () => {
          return {
            fontSize: "16px",
            lineHeight: "16px",
            letterSpacing: 16 * -0.02,
            fontFamily:
              Platform.OS === "web" ? "Lora_500Medium, Lora" : "Lora_500Medium",
          };
        },
      },
    },
    Heading: {
      defaultProps: {
        letterSpacing: -0.2,
        lineHeight: "2xl",
        fontVariant: ["lining-nums", "tabular-nums"],
        fontFamily: "heading",
      },
      baseStyle: {
        color: "grayscale.900",
      },
    },
    FormControl: {
      baseStyle: {
        marginBottom: 4,
      },
    },
    FormControlLabel: {
      baseStyle: {
        marginBottom: "0px",
        _text: {
          fontSize: "2xs",
          color: "grayscale.800",
        },
      },
    },
    Icon: {
      defaultProps: {
        size: "sm",
        color: "icon-primary",
      },
      sizes: {
        "2xs": 2,
        xs: 2,
        ".5xs": 3,
        sm: 4,
        md: 6,
        lg: 7,
        xl: 8,
        "2xl": 9,
        "3xl": 10,
        "4xl": 11,
        "5xl": 12,
        "6xl": 16,
        "12xl": 40,
      },
    },
    Input: {
      baseStyle: (props: any) => {
        const styles: any = {
          borderRadius: "8",
          _invalid: {
            borderColor: "error.500",
            _stack: {
              style: {
                boxShadow: `none`,
              },
            },
            _hover: { borderColor: "error.500" },
          },
        };
        if (props.isReadOnly) {
          styles.bg = "outdated.neutrals.800";
          styles.color = "outdated.neutrals._55";
          styles.borderWidth = 0;
        }
        return styles;
      },
      defaultProps: {
        size: "md",
        py: "16px",
        px: "5",
      },
      variants: {
        outline: (props: any) => {
          const styles: any = {
            borderWidth: "2",
          };
          if (props.isReadOnly) {
            styles.borderColor = "transparent";
          }
          if (props.onContentSizeChange) {
            styles._focus = {
              borderColor: "outdated.neutrals.black",
            };
          }
          return styles;
        },
      },
      sizes: {
        sm: { fontSize: 14 },
        md: { fontSize: 16 },
      },
    },
    InputGroup: {
      baseStyle: (props: any) => {
        const styles: any = {
          borderRadius: "8",
        };
        if (props.isReadOnly) {
          styles.bg = "outdated.neutrals.800";
          styles.color = "outdated.neutrals._55";
          styles.borderWidth = 0;
        }
        return styles;
      },
      defaultProps: {
        size: "md",
        py: "18px",
        px: "5",
      },
      variants: {
        outline: (props: any) => {
          const styles: any = {
            borderWidth: "2",
          };
          if (props.isReadOnly) {
            styles.borderColor = "transparent";
          }
          if (props.onContentSizeChange) {
            styles._focus = {
              borderColor: "outdated.neutrals.black",
            };
          }
          return styles;
        },
      },
      sizes: {
        md: { fontSize: 16 },
      },
    },
    Switch: {
      ...SwitchStyles,
    },
  },
});

export type CustomWhooshThemeType = typeof WhooshTheme;

declare module "native-base" {
  interface ICustomTheme extends CustomWhooshThemeType {}
}

import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path, Circle } from "react-native-svg";

export const PersonFilledIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      fill="currentColor"
      d="M10.154 9H5.846A3.846 3.846 0 0 0 2 12.846C2 13.483 2.517 14 3.154 14h9.692c.637 0 1.154-.517 1.154-1.154A3.846 3.846 0 0 0 10.154 9Z"
    />
    <Circle cx={8} cy={5} r={3} fill="currentColor" />
  </Icon>
);

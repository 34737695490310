import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const AlertIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" {...props}>
    <Path
      fill="currentColor"
      fillRule="evenodd"
      d="m.938 12.476 6.21-10.092a1 1 0 0 1 1.704 0l6.21 10.092A1 1 0 0 1 14.211 14H1.79a1 1 0 0 1-.852-1.524ZM8 4.5a.75.75 0 0 1 .75.75V9a.75.75 0 1 1-1.5 0V5.25A.75.75 0 0 1 8 4.5Zm1 7a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
      clipRule="evenodd"
    />
  </Icon>
);

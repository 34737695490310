import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path, G, Defs, ClipPath } from "react-native-svg";

export const BellIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" {...props}>
    <G clipPath="url(#BellIcon)" stroke="none">
      <Path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="m2.606 10.73.523.54-.523-.54a1.177 1.177 0 0 0 .81 2.02h9.167a1.177 1.177 0 0 0 .81-2.02l-.522.54.523-.54-.894-.866V7.152c0-1.939-.985-3.7-2.819-4.388C9.606 1.897 8.863 1.25 8 1.25c-.863 0-1.605.646-1.681 1.513C4.479 3.45 3.5 5.208 3.5 7.152v2.712l-.894.867Z"
      />
      <Path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13 1c1.5.5 2 2 2 3"
      />
      <Path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13 1c1.5.5 2 2 2 3M3 1c-1.5.5-2 2-2 3"
      />
      <Path fill="currentColor" d="M6 14h4a2 2 0 1 1-4 0Z" />
    </G>
    <Defs>
      <ClipPath id="BellIcon">
        <Path fill="#fff" stroke="none" d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Icon>
);

import { t } from "@lingui/macro";

import { ErrorScreenTemplate } from "../ErrorScreenTemplate";

export const BasicErrorScreen = (props: {
  resetError?: () => void;
  logoutButton?: JSX.Element;
}) => {
  const tryAgain = () => {
    if (window?.location) {
      window.location.reload();
    } else if (!!props.resetError) {
      props.resetError();
    }
  };

  return (
    <ErrorScreenTemplate
      title={t`Whoops! Something didn't work.`}
      message={t`We're sorry, we'll have to take a mulligan on that one.`}
      subMessage={t`If this keeps happening, your problem may be resolved by logging back in.`}
      logoutButton={props.logoutButton}
      onTryAgainClick={tryAgain}
    />
  );
};

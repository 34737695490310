import { createText } from "@shopify/restyle";
import React from "react";

import { Theme } from "./../../../theme/restyle/theme";

const TextNode = createText<Theme>();

export type WhooshTextProps = React.ComponentProps<typeof TextNode>;

export const Text = (props: WhooshTextProps) => {
  return <TextNode maxFontSizeMultiplier={1.5} {...props} />;
};

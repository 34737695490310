import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path, Rect, G } from "react-native-svg";

export const PercentIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" {...props}>
    <G
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <Path d="m5 13.5 6-11" />
      <Rect width={3} height={4} x={10} y={9} rx={1.5} />
      <Rect width={3} height={4} x={3} y={3} rx={1.5} />
    </G>
  </Icon>
);

import { MotionStyle } from "framer-motion";
import React, { ReactNode } from "react";

import { DesignSize, WhooshVariants } from "@/design-system/theme";

import { iStyledButtonProps, UI } from "./ui";

export enum ButtonShape {
  DEFAULT = "default",
  PILL = "pill",
}

interface iButtonProps {
  children?: ReactNode;
  variant?: WhooshVariants;
  onClick?: () => void;
  shape?: ButtonShape;
  size?: DesignSize;
  disabled?: boolean;
  type?: any;
  className?: any;
  leftIcon?: ReactNode;
  href?: string;
  style?: MotionStyle;
  ignoreIconStyles?: boolean;
  "data-cy"?: string;
  "aria-label"?: string;
  testID?: string;
}

const defaultButtonProps: iStyledButtonProps = {
  variant: WhooshVariants.PRIMARY,
  shape: ButtonShape.DEFAULT,
};
export const Button: React.FC<iButtonProps> = ({ children, ...props }) => {
  const mergedProps = { ...defaultButtonProps, ...props };
  return (
    <UI.StyledButton
      role="button"
      href={props.href}
      disabled={props.disabled}
      {...mergedProps}
      whileTap={props.disabled ? {} : { scale: 0.95 }}
      size={props.size}
      style={props.style}
      data-cy={props["data-cy"]}
      onClick={props.onClick}
      aria-label={props["aria-label"]}
    >
      {props.leftIcon && <UI.LeftIconWrap>{props.leftIcon}</UI.LeftIconWrap>}
      {children}
    </UI.StyledButton>
  );
};

import { Icon, IIconProps } from "native-base";
import * as React from "react";
import { Path, Circle } from "react-native-svg";

export const ToolTipIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8 11V8"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Circle cx="8" cy="5.5" r="1" fill="white" />
  </Icon>
);

import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const ChevronMiniFullUpIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 16 16" fill="currentColor" {...props}>
    <Path
      fill="currentColor"
      d="m8.354 6.354 2.792 2.792a.5.5 0 0 1-.353.854H5.207a.5.5 0 0 1-.353-.854l2.792-2.792a.5.5 0 0 1 .708 0Z"
    />
  </Icon>
);

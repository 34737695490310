import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const ErrorIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 32 32" fill="none" {...props}>
    <Path
      fill="#F0F0F0"
      d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12Z"
    />
    <Path
      fill="#BDC7C7"
      fillRule="evenodd"
      d="M16 5C9.925 5 5 9.925 5 16s4.925 11 11 11 11-4.925 11-11S22.075 5 16 5ZM3 16C3 8.82 8.82 3 16 3s13 5.82 13 13-5.82 13-13 13S3 23.18 3 16Z"
      clipRule="evenodd"
    />
    <Path
      fill="#BDC7C7"
      fillRule="evenodd"
      d="m7.293 23.293 16-16 1.414 1.414-16 16-1.414-1.414Z"
      clipRule="evenodd"
    />
  </Icon>
);

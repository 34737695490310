import React from "react";

import { Box } from "../Box/Box";

type BoxProps = React.ComponentProps<typeof Box>;

export type StackProps = BoxProps & {
  children?:
    | JSX.Element
    | (JSX.Element[] | JSX.Element | null)[]
    | null
    | React.ReactNode;
  testID?: string;
};

export const Stack = ({ children, ...restyleProps }: StackProps) => {
  return (
    <Box flexDirection={"column"} {...restyleProps}>
      {children}
    </Box>
  );
};

import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const StarIcon = (props: IIconProps) => {
  return (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M9.114 2.58c-.343-1.107-1.885-1.107-2.228 0l-.942 3.036H2.919c-.586 0-.982.406-1.114.83-.131.423-.036.967.414 1.31L4.696 9.64l-.95 3.062c-.164.526.047 1.03.414 1.31.372.282.937.352 1.4 0L8 12.154l2.44 1.856a1.146 1.146 0 001.4 0c.367-.279.578-.783.414-1.309l-.95-3.062 2.477-1.884c.45-.343.545-.887.414-1.31a1.163 1.163 0 00-1.114-.83h-3.025l-.942-3.035zm1.012 3.26h0z"
        stroke="#001A19"
        fill="none"
        strokeWidth={1.5}
      />
    </Icon>
  );
};

const SwitchStyles = {
  baseStyle: () => ({
    onTrackColor: "grayscale.500",
    offTrackColor: "grayscale.500",
    onThumbColor: "grayscale.900",
    offThumbColor: "grayscale.900",
    borderRadius: "100",
  }),
  defaultProps: {
    variant: "primary",
    size: "md",
    _hover: {
      onTrackColor: "grayscale.500",
      offTrackColor: "grayscale.500",
      onThumbColor: "grayscale.900",
      offThumbColor: "grayscale.900",
    },
  },
};

export default SwitchStyles;

import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const CalendarIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" {...props}>
    <Path
      d="M13.4444 2H2.55556C1.69645 2 1 2.6467 1 3.44444V13.5556C1 14.3533 1.69645 15 2.55556 15H13.4444C14.3036 15 15 14.3533 15 13.5556V3.44444C15 2.6467 14.3036 2 13.4444 2Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <Path
      d="M11 1V4"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <Path
      d="M5 1V4"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <Path
      d="M1 7H15"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Icon>
);
